/* eslint-disable max-len */
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-user-dialog',
  template: `
    <div class="delete-user-dialog">
      <h1 mat-dialog-title class="mat-headline-6">DANGER ZONE</h1>
      <div mat-dialog-content>
        <p>Deleting your account is an irreversible action.</p>
        <p>Type <strong>DELETE</strong> to confirm deletion:</p>
        <mat-form-field>
          <input
            [disabled]="teamsToDelete && teamsToDelete.length > 0"
            matInput [(ngModel)]="confirmationText" (keyup.enter)="confirm()">
        </mat-form-field>
      </div>
      <div *ngIf="teamsToDelete && teamsToDelete.length > 0" class="error-message">
        Before you delete your profile, you must delete these teams or remove yourself from them:
        <ul>
          <li *ngFor="let team of teamsToDelete">{{ team.name }}</li>
        </ul>
      </div>
      <div mat-dialog-actions>
        <button mat-button (click)="confirm()" [disabled]="confirmationText !== 'DELETE' || (teamsToDelete && teamsToDelete.length > 0)">Delete</button>
        <button mat-button mat-dialog-close>Cancel</button>
      </div>
    </div>
  `,
  styles: [`
    .error-message {
      color: red;
      margin-bottom: 10px;
      padding: 10px;
    }
  `],
  encapsulation: ViewEncapsulation.None,
})
export class DeleteUserDialogComponent {
  confirmationText = '';
  teamsToDelete: any[];

  constructor(
    public dialogRef: MatDialogRef<DeleteUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (Array.isArray(data)) {
      this.teamsToDelete = data;
    }
  }

  confirm(): void {
    if (this.confirmationText === 'DELETE') {
      this.dialogRef.close(true);
    }
  }
}
