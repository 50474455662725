import { Component, input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { GthUserModel } from '@sentinels/models';

import { CarouselComponent } from '../carousel/carousel.component';
import { UserCardComponent } from '../user-card/user-card.component';

@Component({
  selector: 'app-nearby-players',
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    RouterLink,
    CarouselComponent,
    UserCardComponent,
  ],
  templateUrl: './nearby-players.component.html',
  styleUrl: './nearby-players.component.scss',
})
export class NearbyPlayersComponent {
  users = input<GthUserModel[]>();
}
