import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { StripeCreateType } from '@sentinels/enums';
import { SrvSafeWindowService } from '@sentinels/services/safe-window.service';

import { environment } from '../../../../../../environments/environment';
import { GthAuthService } from '../../services/auth.service';
import { CountryService } from '../../services/country-code.service';
import { StripeService } from '../../services/stripe.service';

export interface ConnectStripeDialogOpenContract {
  platform: 'gth' | 'meh',
}

@Component({
  selector: 'gth-connect-stripe-dialog',
  templateUrl: './connect-stripe-dialog.component.html',
  styleUrls: ['./connect-stripe-dialog.component.scss'],
  standalone: true,
  imports: [
    NgIf, AsyncPipe, NgFor,
    MatSelectModule,
    MatButtonModule,
    MatProgressBarModule,
    MatDialogModule,
    MatProgressSpinnerModule,
  ],
})
export class ConnectStripeDialogComponent implements OnInit {
  public createType = StripeCreateType;
  public selectedCountry = 'US';
  public user = this.auth.getCurrentUser$();
  createLoading = false;
  linkLoading = false;
  platform: 'gth' | 'meh' = 'gth';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConnectStripeDialogOpenContract,
    private stripe: StripeService,
    private auth: GthAuthService,
    private safeWindow: SrvSafeWindowService,
    private cs: CountryService,
  ) { }

  ngOnInit() {
    if (this.data?.platform) this.platform = this.data.platform;
  }

  getCountries(): { name: string; code: string; }[] {
    return this.cs.getCountries();
  }

  async linkOrCreateStripeAccount(type: StripeCreateType) {
    try {
      type === StripeCreateType.NEW ? this.createLoading = true : this.linkLoading = true;
      const path = await this.stripe
        .linkOrCreateStripeAccount({
          type,
          country: this.selectedCountry,
          platform: this.platform,
          environment: environment.envName,
        }).catch((error) => {
          throw error;
        });
      if (!path) return;
      this.safeWindow.navigate(path);
    } catch (error) {
      // eslint-disable-next-line max-len
      console.error(`Something went wrong ${type === StripeCreateType.NEW ? 'creating' : 'linking'} Stripe account`, error);
    } finally {
      type === StripeCreateType.NEW ? this.createLoading = true : this.linkLoading = false;
    }
  }
}
