import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UnregisteredUserService } from '@sentinels/services/firebase/unregistered-user.service';
import { catchError, map, mergeMap, of } from 'rxjs';

import * as UnregUser from './actions';

@Injectable()
export class UnregisteredUserEffects {
  readonly loadUnregisteredUser$ = createEffect(() => this.actions$.pipe(
    ofType(UnregUser.UnregisteredUserActionTypes.UnregisteredUserLoadOne),
    mergeMap((action) =>
      this.unregUserService.getUnregisteredUser$(action.uid).pipe(
        map((user) => {
          if (!user) throw new Error('User not found');
          return UnregUser.unregisteredUserLoadOneSuccess({ user });
        }),
        catchError((error) => of(UnregUser.unregisteredUserLoadOneError({ error }))),
      ),
    ),
  ));

  readonly loadUnregisteredUserByEmail$ = createEffect(() => this.actions$.pipe(
    ofType(UnregUser.UnregisteredUserActionTypes.UnregisteredUserLoadOneByEmail),
    mergeMap((action) =>
      this.unregUserService.getUnregisteredUserByEmail$(action.email).pipe(
        map((user) => {
          if (user) return UnregUser.unregisteredUserLoadOneByEmailSuccess({ user });
          throw new Error('User not found');
        }),
        catchError((error) => of(UnregUser.unregisteredUserLoadOneByEmailError({ error }))),
      ),
    ),
  ));

  readonly deleteUnregisteredUser$ = createEffect(() => this.actions$.pipe(
    ofType(UnregUser.UnregisteredUserActionTypes.UnregisteredUserDeleteOne),
    mergeMap((action) =>
      this.unregUserService.deleteGuestAccount$(action.uid).pipe(
        map(() => UnregUser.unregisteredUserDeleteOneSuccess({ uid: action.uid })),
        catchError((error) => of(UnregUser.unregisteredUserDeleteOneError({ error }))),
      ),
    ),
  ));

  readonly signUpUnregisteredUser$ = createEffect(() => this.actions$.pipe(
    ofType(UnregUser.UnregisteredUserActionTypes.UnregisteredUserSignUp),
    mergeMap((action) =>
      this.unregUserService.signUpUnregisteredUser$(action.uid, action.newUid).pipe(
        map(() => UnregUser.unregisteredUserDeleteOne({ uid: action.uid })),
        catchError((error) => of(UnregUser.unregisteredUserSignUpError({ error }))),
      ),
    ),
  ));

  readonly createUnregisteredUser$ = createEffect(() => this.actions$.pipe(
    ofType(UnregUser.UnregisteredUserActionTypes.UnregisteredUserCreateOne),
    mergeMap((action) =>
      this.unregUserService.createGuestAccount$(action.user).pipe(
        map(() => UnregUser.unregisteredUserCreateOneSuccess({ user: action.user })),
        catchError((error) => of(UnregUser.unregisteredUserCreateOneError({ error }))),
      ),
    ),
  ));

  constructor(
    private actions$: Actions<UnregUser.UnregisteredUserActionsUnion>,
    private unregUserService: UnregisteredUserService,
  ) {}
}
