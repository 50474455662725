import { DatePipe, IMAGE_CONFIG, NgOptimizedImage, provideImageKitLoader, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, ElementRef, inject, input, viewChild } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { GthEventItemModel } from '@sentinels/models';
import { APP_ROUTES } from '@shared/helpers';
import { filter, switchMap } from 'rxjs';

import { EventImageService } from '../../../services/event-image/event-image.service';
import { ExtractColorService } from '../../../services/extract-color/extract-color.service';

@Component({
  selector: 'app-upcoming-event-item',
  standalone: true,
  imports: [
    DatePipe,
    MatIconModule,
    TitleCasePipe,
    RouterLink,
    NgOptimizedImage,
  ],
  templateUrl: './upcoming-event-item.component.html',
  styleUrl: './upcoming-event-item.component.scss',
  providers: [
    {
      provide: IMAGE_CONFIG,
      useValue: {
        placeholderResolution: 50,
      },
    },
    provideImageKitLoader('https://ik.imagekit.io/67ng46hjy'),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpcomingEventItemComponent {
  private eventImageService = inject(EventImageService);
  private extractColorService = inject(ExtractColorService);

  public event = input<GthEventItemModel>();
  public generalCard = input<boolean>(false);

  private routes = APP_ROUTES;

  private bannerImgRef = viewChild<ElementRef<HTMLImageElement>>('bannerImage');
  private bannerHue = toSignal(toObservable(this.bannerImgRef).pipe(
    filter((img) => !!img),
    switchMap((img) => this.extractColorService.getColorHue$(img.nativeElement)),
  ), { initialValue: null });
  public bannerBgOklch = computed<string>(() =>
    this.generalCard() ?
      'white' :
      `oklch(96% ${this.bannerHue() ? '0.025' : '0'} ${this.bannerHue() ?? '0'})`,
  );
  public bannerBorderOklch = computed<string>(() =>
    this.generalCard() ?
      'rgba(0, 0, 0, 0.12)' :
      `oklch(80% ${this.bannerHue() ? '0.04' : '0'} ${this.bannerHue() ?? '0'})`,
  );

  public bannerImg = toSignal(toObservable(this.event).pipe(
    switchMap((event) =>
      this.eventImageService.getEventImgObj$(event.gameType, event.banner),
    ),
  ));

  public cardLink = computed(() =>
    this.generalCard() ? this.routes.Profile : ['/evt/', this.event()?.id],
  );
}
