import { Component, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { EventJoinerModel } from '@index/models/event-joiner';
import { GthEventItemModel } from '@sentinels/models';
import { APP_ROUTES } from '@shared/helpers';

import { CarouselComponent } from '../carousel/carousel.component';
import { EventCardComponent } from '../event-card/event-card.component';

export interface NearbyEvent {
  event: GthEventItemModel,
  joiners: EventJoinerModel[],
}

@Component({
  selector: 'app-nearby-events',
  standalone: true,
  imports: [
    CarouselComponent,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    EventCardComponent,
    RouterLink,
  ],
  templateUrl: './nearby-events.component.html',
  styleUrl: './nearby-events.component.scss',
})
export class NearbyEventsComponent {
  cityName = input<string>();
  eventPlayerObjects = input<NearbyEvent[]>();

  public routes = APP_ROUTES;
}
