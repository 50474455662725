import { provideHttpClient, withFetch } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { environment } from '@environments/environment';
import { Loader } from '@googlemaps/js-api-loader';
import { APP_EFFECTS, GTH_ENVIRONMENT, GthAuthService } from '@gth-legacy';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppWelcomeDialogModule } from '@shared/dialogs/welcome-dialog/welcome-dialog.module';
import { AppSharedModule } from '@shared/shared.module';
import { StripeModule } from 'stripe-angular';

import { appRoutes } from './app.routes';
import { AuthModule } from './features/auth/auth.module';
import { GameListComponentModule } from './features/discover/game/list/game-list.module';
import { PlayerListComponentModule } from './features/discover/player-list/player-list.module';
import { PaymentsModule } from './features/payments/payments.module';
import { FirebaseProviders } from './firebase-modules';
import { GameInfoResolver } from './route-resolvers';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideHttpClient(withFetch()),
    ...FirebaseProviders,
    GameInfoResolver,
    GthAuthService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3500 } },
    {
      provide: GTH_ENVIRONMENT,
      useValue: {
        root: 'https://app.gametimehero.com',
        appName: 'Gametime Hero',
        teamsEnabled: true,
      },
    },
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: environment.googleMaps,
        libraries: ['places'],
      }),
    },
    importProvidersFrom(
      AuthModule,
      AppWelcomeDialogModule,
      GameListComponentModule,
      PlayerListComponentModule,
      AppSharedModule,
      NgxGpAutocompleteModule,
      PaymentsModule,
      StripeModule.forRoot(environment.stripe),
      StoreModule.forRoot({}, {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      }),
      EffectsModule.forRoot(APP_EFFECTS),
    ),
    provideServiceWorker('combined-sw.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
