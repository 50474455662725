import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { StripeCreateType } from '@sentinels/enums';
import { GthUserModel } from '@sentinels/models';
import { SrvSafeWindowService } from '@sentinels/services/safe-window.service';
import { take } from 'rxjs/operators';

import { environment } from '../../../../../../environments/environment';
import { UnlinkStripeDialogComponent } from '../../dialogs/unlink-stripe-dialog/unlink-stripe-dialog.component';
import { StripeRequirementsToTitleCasePipe } from '../../pipes/stripe-requirements-to-title-case.pipe';
import { StripeService } from '../../services/stripe.service';
import { StripeIconComponent } from '../stripe-icon/stripe-icon.component';

export interface StripeAccountRequirements {
  alternatives: {
    // eslint-disable-next-line camelcase
    alternative_fields_due: string[],
    // eslint-disable-next-line camelcase
    original_fields_due: string[],
  }[],
  // eslint-disable-next-line camelcase
  current_deadline: null | number,
  // eslint-disable-next-line camelcase
  currently_due: string[],
  // eslint-disable-next-line camelcase
  disabled_reason: string,
  errors: {
    // https://stripe.com/docs/api/accounts/object#account_object-requirements-errors-code
    code: string,
    reason: string,
    requirement: string,
  }[],
  // eslint-disable-next-line camelcase
  eventually_due: string[],
  // eslint-disable-next-line camelcase
  past_due: string[],
  // eslint-disable-next-line camelcase
  pending_verification: string[],
}

@Component({
  selector: 'gth-stripe-connected-account',
  templateUrl: './stripe-connected-account.component.html',
  styleUrls: ['./stripe-connected-account.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    StripeIconComponent,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatMenuModule,
    StripeRequirementsToTitleCasePipe,
  ],
})
export class GthStripeConnectedAccountComponent implements OnInit {
  @Input()
  user?: GthUserModel;
  @Output()
  userChange = new EventEmitter<GthUserModel>();

  @Input()
  stripeAuthorizationCode?: string;
  @Output()
  stripeAuthorizationCodeChange = new EventEmitter<string | undefined>;

  @Input()
  platform: 'gth' | 'meh' = 'gth';

  loadingRequirements = false;
  linkingStripeAccount = false;
  stripeAccountRequirements: StripeAccountRequirements;
  loadingCompleteVerification = false;

  constructor(
    private stripe: StripeService,
    private safeWindow: SrvSafeWindowService,
    private snackbar: MatSnackBar,
    private clipboard: Clipboard,
    private dialog: MatDialog,
    private router: Router,
  ) { }
  async ngOnInit() {
    if (this.stripeAuthorizationCode) {
      try {
        this.linkingStripeAccount = true;
        const response = await this.stripe.connectOAuth(this.stripeAuthorizationCode);
        if (!response) {
          throw new Error(
            'Something went wrong linking stripe account from authorization code',
          );
        }
        const {
          stripeId,
          stripeChargesEnabled,
          stripeDetailsSubmitted,
        } = response;
        this.user.stripeId = stripeId;
        this.user.stripeChargesEnabled = stripeChargesEnabled;
        this.user.stripeDetailsSubmitted = stripeDetailsSubmitted;
        this.userChange.emit(this.user);
        if (stripeId) {
          this.snackbar.open(
            'Successfully linked Stripe account',
            undefined,
            { duration: 5000 },
          );

          if (this.stripeAccountIncomplete(this.user)) {
            await this.loadStripeRequirements(stripeId);
          }
        } else {
          this.snackbar.open(
            'Something went wrong linking Stripe account',
            undefined,
            { duration: 5000 },
          );
        }
      } catch (error: unknown) {
        // eslint-disable-next-line max-len
        console.error(error);
      } finally {
        this.stripeAuthorizationCodeChange.emit(undefined);
        await this.removeStripeQueryParams();
        this.linkingStripeAccount = false;
      }
    } else if (this.user.stripeId && this.stripeAccountIncomplete(this.user)) {
      await this.loadStripeRequirements(this.user.stripeId);
    }
  }

  stripeAccountUrl = (accountId: string) => `https://dashboard.stripe.com/${accountId}`;

  stripeAccountIncomplete = (user: GthUserModel) => {
    return !user?.stripeChargesEnabled || !user?.stripeDetailsSubmitted;
  };
  stripeAccountComplete = (user: GthUserModel) => {
    return user?.stripeChargesEnabled || user?.stripeDetailsSubmitted;
  };

  identifyRequirements(_: number, requirement: string) {
    return requirement;
  }

  async onUnlinkBtnClick() {
    if (!this.user.stripeId) return;

    const dialogRef = this.dialog.open(UnlinkStripeDialogComponent, { maxWidth: 340 });

    dialogRef.afterClosed().pipe(take(1)).forEach(async (unlinked: boolean) => {
      if (unlinked) {
        this.user.stripeId = null;
        this.user.stripeChargesEnabled = null;
        this.user.stripeDetailsSubmitted = null;
        this.userChange.emit(this.user);
      }
    });
  }

  async onCompleteVerificationBtnClick() {
    if (!this.user.stripeId) return;

    try {
      this.loadingCompleteVerification = true;
      const path = await this.stripe.linkOrCreateStripeAccount({
        type: StripeCreateType.NEW,
        platform: this.platform,
        environment: environment.envName,
      });
      if (!path) return;
      this.snackbar.open('Taking you to Stripe', undefined, { duration: 3000 });
      this.safeWindow.navigate(path);
    } catch (error) {
      console.error('Something went wrong completing Stripe verification', error);
    } finally {
      this.loadingCompleteVerification = false;
    }
  }

  onCopyStripeAccountIdBtnClick() {
    if (!this.user.stripeId) {
      return;
    }
    this.clipboard.copy(this.user.stripeId);
  }

  async loadStripeRequirements(stripeAccountId: string) {
    try {
      this.loadingRequirements = true;
      const stripeAccount = await this.stripe.getAccount(stripeAccountId)
        .catch((error) => {
          throw error;
        });
      this.stripeAccountRequirements = stripeAccount.requirements;
    } catch (error: unknown) {
      console.error('Something went wrong retrieving stripe account requirements', error);
    } finally {
      this.loadingRequirements = false;
    }
  }

  async removeStripeQueryParams() {
    await this.router.navigate([], {
      queryParams: { code: null, scope: null },
      queryParamsHandling: 'merge',
    });
  }
}
