import { Injectable } from '@angular/core';
import { extractColorsFromImage } from 'extract-colors';
import { FinalColor } from 'extract-colors/lib/types/Color';
import { catchError, from, map, Observable, of, tap } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ExtractColorService {
  getColorInfo$(img: HTMLImageElement): Observable<FinalColor[]> {
    return from(extractColorsFromImage(img, { crossOrigin: 'anonymous' })).pipe(
      catchError((_error) => {
        return of(null);
      }),
    );
  }

  getColorRgb$(img: HTMLImageElement): Observable<number[]> {
    return this.getColorInfo$(img).pipe(
      map((colorObj) => [colorObj[0].red, colorObj[0].green, colorObj[0].blue]),
      catchError((_error) => {
        return of([150, 150, 150]);
      }),
    );
  }

  getColorHue$(img: HTMLImageElement): Observable<number> {
    return this.getColorInfo$(img).pipe(
      map((colorObj) => colorObj[0].hue * 360),
      catchError((_error) => {
        return of(150);
      }),
    );
  }
}
