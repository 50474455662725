<app-nav-bar-layout [navBarOptions]="navBarOptions">
  <div class="wrapper fx-column" *ngIf="{user: user$ | async} as profile">
    <div class="content fx-auto fx-column">
      <div class="search-bar-container">
        <app-search-bar pageTitle="Available Participants" context="participants" [defaultCity]="defaultCity$ | async"
          (filter)="onSearchPerformed($event)">
          <a href="" class="user-update-btn" color="primary" [routerLink]="['/settings']"
            [queryParams]="{tab:'availability'}" mat-raised-button>Update Your Availibility</a>
        </app-search-bar>
      </div>
      <div class="onboarding-container" *ngIf="displayOnboardingStep && !displayMockResults">
        <gth-onboarding-step [step]="OnboardingStep.ParticipantsSearch" (dismiss)="displayOnboardingStep = false" />
      </div>
      <div class="onboarding-container" *ngIf="displayMockResults">
        <gth-onboarding-step [step]="OnboardingStep.MockParticipantsSearch" />
      </div>
      <mat-card appearance="outlined" class="content-card fx-auto fx-column fx-gap-10x">
        <ng-container *ngIf="dataSource$ | async as dataSource">
          <ng-container *ngIf="!noPlayers; else noResultsTmpl">
            <mat-table #table class="fx-auto" [dataSource]="dataSource" matSort>
              <!-- Name Column -->
              <ng-container matColumnDef="displayName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                <mat-cell *matCellDef="let player" class="first-cell">
                  <div class="player-cell-container">
                    <ark-user-avatar [src]="player.photoURL"></ark-user-avatar>
                    <span class="column-main">
                      {{ player.displayName }}
                    </span>
                  </div>
                </mat-cell>
              </ng-container>

              <!-- Availability Column -->
              <ng-container matColumnDef="Activities">
                <mat-header-cell *matHeaderCellDef class="tertiary-info">Activities They Enjoy</mat-header-cell>
                <mat-cell *matCellDef="let player" class="tertiary-info">
                  <p *ngIf="!player.sportAvailability || !player.sportAvailability.length">No Activities Selected</p>
                  <ul>
                    <ng-container *ngIf="player.sportAvailability.length <= 2; else moreSports">
                      <li *ngFor="let sport of player.sportAvailability">{{sport.sport}}</li>
                    </ng-container>
                    <ng-template #moreSports>
                      <li *ngFor="let sport of player.sportAvailability.slice(0, 2)">{{sport.sport}}</li>
                      <li>and {{ player.sportAvailability.length - 2 }} more...</li>
                    </ng-template>
                  </ul>
                </mat-cell>
              </ng-container>

              <!-- Action Column -->
              <ng-container matColumnDef="Action">
                <mat-header-cell *matHeaderCellDef class="action-cell" />
                <mat-cell *matCellDef="let player" class="action-cell fx-row fx-align-end">
                  <button class="hide-small" mat-icon-button matTooltip="Open User Availability" (click)="openAvailabilityDialog(player)">
                    <mat-icon color="primary" fontIcon="calendar_today" />
                  </button>

                  <button class="hide-small" mat-icon-button matTooltip="Send Invite" *ngIf="profile.user"
                    (click)="onSendRequestBtnClick(player, profile.user)">
                    <mat-icon color="primary" fontIcon="send" />
                  </button>

                  <a mat-icon-button color="primary" [routerLink]="[APP_ROUTES.Profile, player.uid]"
                    [disabled]="player.uid.startsWith('mock-')">
                    <mat-icon fontIcon="account_circle" />
                  </a>

                  <div matTooltip="User does not accept messages"
                    [matTooltipDisabled]="player?.privacySettings?.messagesFromAnyone">
                    <button mat-icon-button color="primary" [disabled]="!player?.privacySettings?.messagesFromAnyone"
                      (click)="onMessageBtnClick(player)">
                      <mat-icon fontIcon="message" />
                    </button>
                  </div>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns()" />
              <mat-row *matRowDef="let row; columns: displayedColumns()" />
            </mat-table>

            <mat-paginator class="list-paginator" aria-label="Select page of players" [pageSizeOptions]="[25, 50, 100]"
              [showFirstLastButtons]="!isSmallScreen()" />
          </ng-container>
        </ng-container>
      </mat-card>
    </div>
  </div>
</app-nav-bar-layout>

<!-- <div class="list--loading" *ngIf="loading">
  <mat-spinner diameter="42" />
</div> -->

<ng-template #noResultsTmpl>
  <div class="list--no-results fx-fill fx-centered fx-column fx-gap-10x">
    <img src="/assets/biking.webp" />
    <p>No players found within your search criteria</p>
  </div>

</ng-template>