import { AsyncPipe, Location, NgClass, NgFor, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete';
import { User } from '@index/interfaces';
import { Store } from '@ngrx/store';
import { GthGenderModel, GthUserModel } from '@sentinels/models';
import { SrvApiService } from '@sentinels/services/api.service';
import { UserService } from '@sentinels/services/firebase/user.service';
import { SelectAvatarButtonModule } from '@shared/components/select-avatar-button/select-avatar-button.module';
import {
  AvatarComponent,
  CloseMethod,
  DialogClosedProps,
} from '@shared/dialogs/avatar/avatar.component';
import { APP_ROUTES } from '@shared/helpers';
import firebase from 'firebase/compat/app';
import { geohashForLocation } from 'geofire-common';
import { Subscription } from 'rxjs';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map, take } from 'rxjs/operators';

import { DefaultCity } from '../../../../../../../shared/interfaces/user';
import { APP_STATE, AuthLoad } from '../../../public-api';
import { GthAuthService } from '../../services/auth.service';
import {
  ANALYTICS_CONSTS,
  GthAnalyticsService,
} from '../../services/logging/analytics.service';

export interface MoreInfoFormDetails {
  header: string;
  layout: 'horizontal' | 'vertical';
}

const DEFAULT_PRIVACY_SETTINGS = {
  blocklist: [''],
  messagesFromAnyone: true,
  publicBadges: true,
  publicOnlineStatus: true,
  publicUpcomingGames: true,
};

@Component({
  selector: 'gth-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SelectAvatarButtonModule,
    NgClass,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    AsyncPipe,
    NgxGpAutocompleteModule,
    NgIf,
    NgFor,
  ],
})
export class ProfileFormComponent implements OnInit, OnDestroy {
  @Output()
  @Input()
  showSettingsHeader = false;

  @Input()
  formDetails: MoreInfoFormDetails;

  @Input()
  initialSignUp = false;

  @Output()
  newUser = new EventEmitter<GthUserModel>();

  @Output()
  moreInfoComplete = new EventEmitter<GthUserModel>();

  @ViewChild('avatarIconButton', { read: ElementRef, static: false })
  avatarIconButton!: ElementRef;

  @ViewChild('avatarButton', { read: ElementRef, static: false })
  avatarButton!: ElementRef;

  @ViewChild('cityInput')
  cityInput!: MatInput;

  @ViewChild('placesRef')
  placesRef: NgxGpAutocompleteDirective;

  options = {
    types: ['(cities)'],
    componentRestrictions: { country: 'US' },
  };

  get cityName() {
    const ctrl = this.profileForm.get('city');
    return ctrl && ctrl.value && ctrl.value.city ? ctrl.value.city.name : '';
  }

  get formValid(): boolean {
    if (!this.profileForm.get('checkbox')!.value) return false;
    return this.profileForm.valid;
  }

  get gender() {
    const ctrl = this.profileForm.get('gender');
    return ctrl ? ctrl.value : '';
  }

  set gender(val: string) {
    const ctrl = this.profileForm.get('gender');
    if (ctrl) {
      ctrl.setValue(val);
    }
  }

  get city() {
    const ctrl = this.profileForm.get('city');
    return ctrl ? ctrl.value : [];
  }

  set city(val: DefaultCity | google.maps.places.PlaceResult | undefined) {
    const ctrl = this.profileForm.get('city');
    if (ctrl) {
      ctrl.setValue(val);
    }
  }

  get checkboxValue() {
    return this.profileForm.get('checkbox')!.value;
  }

  removable = true;
  photoUrlFromSocialMedia: string | null = null;
  uid = '';
  emailVerified = false;
  genders: GthGenderModel[] = [];
  header = '';
  isNewUser = true;
  placeInputOptions: any = {
    types: ['cities'],
    componentRestrictions: { country: 'US' },
  };

  readonly user: GthUserModel | null = null;
  constraints = {
    displayName: { minLength: 2, maxLength: 25 },
    fullName: { minLength: 2, maxLength: 300 },
  };

  profileForm = new FormGroup({
    displayName: new FormControl<string>(
      null,
      {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.minLength(this.constraints.displayName.minLength),
          Validators.maxLength(this.constraints.displayName.maxLength),
        ],
      },
    ),
    fullName: new FormControl(
      null,
      {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.minLength(this.constraints.fullName.minLength),
          Validators.maxLength(this.constraints.fullName.maxLength),
        ],
      },
    ),
    email: new UntypedFormControl(''),
    password: new UntypedFormControl(''),
    checkbox: new UntypedFormControl(false),
    gender: new UntypedFormControl(''),
    sports: new UntypedFormControl([]),
    photoUrl: new UntypedFormControl(''),
    pronouns: new UntypedFormControl(''),
    city: new UntypedFormControl({ name: '', address_components: [] }),
    cityName: new UntypedFormControl(''),
  });

  private subscription = new Subscription();

  constructor(
    private api: SrvApiService,
    private auth: GthAuthService,
    private analytics: GthAnalyticsService,
    private render: Renderer2,
    private router: Router,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    private _location: Location,
    private store: Store<APP_STATE>,
    private usersService: UserService,

  ) {}

  async ngOnInit() {
    if (this.initialSignUp) {
      this.store.dispatch(AuthLoad());
    }
    this.genders = (await this.api.genders.listAsync())
      .filter((g) => g.label !== 'Any');

    const users$ = combineLatest([this.auth.userModel$, this.auth.getCurrentUser$()]).pipe(
      map(([dbUser, authUser]: [GthUserModel, User]) => {
        return dbUser ? dbUser.user : authUser;
      }));

    this.subscription.add(
      users$.pipe(distinctUntilChanged()).subscribe((user: User) => {
        if (!user) {
          return;
        }
        const model = new GthUserModel(user.uid, user);
        this.onUserData(model);
      }),
    );
  }

  public get photoURL() {
    return this.profileForm.controls.photoUrl;
  }

  openDialog() {
    const dialogRef = this.dialog.open(AvatarComponent, {
      width: '600px',
      height: '675px',
      data: {
        showSocialMediaButton: !!this.photoUrlFromSocialMedia,
      },
      backdropClass: 'gth-overlay-backdrop',
    });

    this.analytics.logEvent(ANALYTICS_CONSTS.avatarDialogShown);

    dialogRef.afterClosed().subscribe((result: DialogClosedProps | null) => {
      // Don't change image if close method wasn't CloseMethod.SAVE
      if (!result || result.closeMethod !== CloseMethod.SAVE) return;

      // Change image to one from social media account ;)
      if (result.useSocialMedia && this.photoUrlFromSocialMedia) {
        this.profileForm.get('photoUrl')!.setValue(this.photoUrlFromSocialMedia);
        return;
      }

      // Don't change image if no image ;)
      if (!result.selectedImage) return;
      this.profileForm.get('photoUrl')!.setValue(result.selectedImage);
    });
  }

  onAutocompleteSelected(place: any) {
    if (!place || !place.geometry || !place.geometry.location) {
      return;
    }
    const city = {
      name: place.formatted_address,
      lat: place.geometry!.location.lat(),
      lng: place.geometry!.location.lng(),
    };
    this.profileForm.get('city')!.setValue({
      name: city.name,
      lat: city.lat,
      lng: city.lng,
    });
    this.profileForm.get('cityName')!.setValue(place.name);
  }

  clearCityInput() {
    const emptyData = { name: '', address_components: [] };
    this.city = emptyData;
  }

  onUserData(user: GthUserModel) {
    this.photoUrlFromSocialMedia = user.photoURL || null;
    this.profileForm.get('photoUrl')!.setValue(user.photoURL);

    const displayNameControl = this.profileForm.get('displayName')!;
    const fullNameControl = this.profileForm.get('fullName')!;
    const emailControl = this.profileForm.get('email')!;

    displayNameControl.setValue(displayNameControl.value|| user.displayName);
    fullNameControl.setValue(fullNameControl.value|| user.fullName);
    emailControl!.setValue(user.email);

    this.uid = user.uid;
    this.emailVerified = user.emailVerified || false;

    if (fullNameControl.valid && emailControl.valid) {
      this.profileForm.controls.email.disable();
    }
    this.isNewUser = !user.hasAdditionalInfo;
    this.header = this.isNewUser ? 'More Information' : 'Settings';

    if (!this.isNewUser) {
      this.city = user.defaultCity;
      if (this.city.name) {
        this.profileForm.get('cityName')!.setValue(this.city.name);
      }

      this.gender = user.gender;

      const sportsControl = this.profileForm.get('sports')!;
      sportsControl.setValue(user.sports);

      const pronounsControl = this.profileForm.get('pronouns')!;
      pronounsControl.setValue(user.pronoun);
    }
  }

  showIcon() {
    this.render.removeClass(this.avatarIconButton.nativeElement, 'hidden');
  }

  hideIcon() {
    if (!this.profileForm.get('photoUrl')!.value) return;
    this.render.addClass(this.avatarIconButton.nativeElement, 'hidden');
  }

  getUrl() {
    const url = this.profileForm.get('photoUrl')!.value;
    if (!url) return 'none';
    return `url(${this.profileForm.get('photoUrl')!.value})`;
  }

  async createUserFromFormData(): Promise<GthUserModel> {
    const place = this.city as google.maps.places.PlaceResult;
    const lat = place.geometry ? place.geometry!.location!.lat() : (place as DefaultCity).lat;
    const lng = place.geometry ? place.geometry!.location!.lng() : (place as DefaultCity).lng;
    const hash = geohashForLocation([lat, lng]);

    const defaultCity = {
      lat,
      lng,
      hash,
      name: place.name,
    };

    /** fetch user model from db to assign availability */
    const userModel = await this.auth.userModel$.pipe(take(1)).toPromise();
    const data: User = {
      uid: this.uid,
      emailVerified: this.emailVerified,
      displayName: this.profileForm.get('displayName')!.value,
      fullName: this.profileForm.get('fullName')!.value,
      email: this.profileForm.get('email')!.value,
      defaultCity,
      photoURL: this.profileForm.get('photoUrl')!.value,
      additionalInfo: {
        gender: this.gender,
        sports: this.profileForm.get('sports')!.value,
        pronoun: this.profileForm.get('pronouns')!.value,
      },
      availability: userModel?.availability ?? null, // possibly null if not already a user
      privacySettings: userModel?.privacySettings ?? DEFAULT_PRIVACY_SETTINGS,
      createdAt: userModel?.createdAt ?? firebase.firestore.Timestamp.now(),
      updatedAt: firebase.firestore.Timestamp.now(),
      stripeCustomerId: userModel?.stripeCustomerId ?? null,
      stripeId: userModel?.stripeId ?? null,
      stripeChargesEnabled: userModel?.stripeChargesEnabled ?? false,
      stripeDetailsSubmitted: userModel?.stripeDetailsSubmitted ?? false,
      userSubscription: userModel?.userSubscription ?? 'Free',
      bio: userModel?.bio ?? null,
      // phoneNumber: this.profileForm.controls.phoneNumber.value ?? userModel?.phoneNumber,
    };
    return new GthUserModel(this.uid, data);
  }

  async setAdditionalInfo() {
    const data = await this.createUserFromFormData();
    this.analytics.logEvent(ANALYTICS_CONSTS.settingsSave);

    /** todo: If phone number changed, prompt verification */
    await this.usersService.updateUser(data)
      .then(async (success) => {
        if (success) {
          if (!this.isNewUser) {
            this.snackbar.open('Successfully updated your profile');

            this.analytics.logEvent(ANALYTICS_CONSTS.settingsUpdated, {
              context: 'more-info',
            });

            if (this.isNewUser) {
              this.router.navigate([APP_ROUTES.Profile]);
            }
          } else {
            this.analytics.logEvent(ANALYTICS_CONSTS.settingsCreated);

            this.newUser.emit(data);
          }

          this.store.dispatch(AuthLoad());
          this.moreInfoComplete.emit(data);
        }
      });
  }

  isFormValid() {
    const hasCity = this.city && !!this.city.name;
    return !(this.profileForm.valid && hasCity);
  }

  backClicked() {
    this._location.back();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
