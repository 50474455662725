import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FlatpickrModule } from 'angularx-flatpickr';

import { CalendarComponent, MilitaryTo12HourPipe } from './calendar.component';

@NgModule({
  declarations: [CalendarComponent, MilitaryTo12HourPipe],
  imports: [
  CommonModule,
  FlatpickrModule.forRoot(),
  CalendarModule.forRoot({
    provide: DateAdapter,
    useFactory: adapterFactory,
  }),
  FormsModule,
  MatButtonToggleModule,
  ],

  exports: [CalendarComponent],
})
export class CalendarComponentModule {}
