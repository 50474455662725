<h1>Interests, Proficiency & Passions</h1>
<ng-container *ngIf="user$ | async as user">
  <div class="sports-availability-container">
    <ng-container *ngIf="onAvailabilities as on">
      <ng-container *ngFor="let sport of on">
        <div *ngIf="sport.toggle === true" class="sport-availability" [style.display]="sport.toggle ? 'block' : 'none'">
          <app-sports-toggles [sportsAvailability]="sport"
            (availabilityChange)="onSportsAvailabilityChange(user, $event, onAvailabilities, offAvailabilities, true)">
          </app-sports-toggles>
        </div>
      </ng-container>
    </ng-container>

  </div>
  <div class="center-link">
    <button mat-button class="show-hide-link" (click)="toggleHiddenToggles($event)">
      {{ hiddenToggles ? 'Show all interests': 'Hide all interests' }}
    </button>
  </div>
  <div class="sports-availability-container">
    <ng-container *ngIf="!hiddenToggles">
      <ng-container *ngIf="offAvailabilities as off">
        <ng-container *ngFor="let sport of off">
          <div *ngIf="sport.toggle === false" class="sport-availability"
            [style.display]="sport.toggle ? 'none' : 'block'">
            <app-sports-toggles [sportsAvailability]="sport"
              (availabilityChange)="onSportsAvailabilityChange(user, $event, offAvailabilities, onAvailabilities, false)">
            </app-sports-toggles>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <section class="personality-endorsements">
    <app-personality-toggles [endorsements]="user.endorsements" (personalityUpdate)="onPersonalityUpdate(user, $event)" />
  </section>
  <!--
  <div class="dupr-rating fx-column">
    <h2>
      DUPR Rating
      <mat-icon class="info-icon" (click)="openInstructionsDialog()">info_outline</mat-icon>
    </h2> 
    <mat-form-field appearance="outline" [formGroup]="formGroup">
      <mat-label>DUPR Id</mat-label>
      <input matInput formControlName="duprId" />
      <mat-error *ngIf="!isDuprIdValid()">This is not a valid DUPR Id.</mat-error>
    </mat-form-field>

    <mat-progress-bar mode="indeterminate" *ngIf="showDuprLoading"></mat-progress-bar> 
    <button mat-flat-button color="primary" (click)="onUpdateDuprRating(user)">
      Update DUPR Rating
    </button>
  </div>
  -->
</ng-container>