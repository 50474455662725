import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import {
    ConfirmDialogComponent,
    GthGoogleMapModule,
    GthOnboardingStepModule,
    TemplateTooltipDirective,
} from '@gth-legacy';
import { StoreModule } from '@ngrx/store';
import { AppGamesTableModule } from '@shared/components/games-table/games-table.module';
import { AppSearchBarComponentModule } from '@shared/components/search-bar/search-bar.module';
import { EventRatingModule } from '@shared/dialogs/event-rating/event-rating.module';
import { NavBarLayoutComponent } from '@shared/layouts/nav-bar-layout/views/nav-bar.component';

import { AppGameListComponent } from '../../../../shared/components/game-list/game-list.component';
import { GameListComponent } from './game-list.component';
import { eventItemListReducer } from './state/reducers';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        RouterModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatProgressSpinnerModule,
        TemplateTooltipDirective,
        GthGoogleMapModule,
        AppSearchBarComponentModule,
        ConfirmDialogComponent,
        EventRatingModule,
        AppGameListComponent,
        NavBarLayoutComponent,
        AppGamesTableModule,
        GthOnboardingStepModule,
        MatButtonToggleModule,
        StoreModule.forFeature('eventItemListFeature', eventItemListReducer),
    ],
    providers: [{ provide: MAT_DIALOG_DATA, useValue: {} }],

    exports: [GameListComponent],
    declarations: [GameListComponent],
})
export class GameListComponentModule { }
