// user.effects.ts

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserService } from '@sentinels/services/firebase/user.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import * as User from './actions';

@Injectable()
export class UserEffects {
  readonly loadUser$ = createEffect(() => this.actions$.pipe(
    ofType(User.UserActionTypes.UserLoadOne),
    mergeMap((action) =>
      this.userService.getUser$(action.uid).pipe(
        map((user) =>{
          if (!user) throw new Error('User not found');
          return User.userLoadOneSuccess({ user });
        }),
        catchError((error) => of(User.userLoadOneError({ error }))),
      ),
    ),
  ));

  readonly updateUser$ = createEffect(() => this.actions$.pipe(
    ofType(User.userUpdateOne),
    mergeMap((action) =>
      this.userService.update(action.uid, action.dataToUpdate).pipe(
        map(() => User.userUpateSuccess({})),
        catchError((error) => of(User.userLoadOneError({ error }))),
      ),
    ),
  ));

  loadUsers$ = createEffect(() => this.actions$.pipe(
    ofType(User.UserActionTypes.UserLoadList),
    mergeMap((action) =>
      this.userService.getUsers(action.uids).pipe(
        map((users) => User.userLoadListSuccess({ users })),
        catchError((error) => of(User.userLoadListError({ error }))),
      ),
    ),
  ));


  readonly loadAllEventsByLatLng$ = createEffect(() => this.actions$.pipe(
    ofType(User.userLoadListByLatLng),
    mergeMap((action) => {
      return this.userService.getUsersByLocation$(action.lat, action.lng, true).pipe(
        map((users) => {
          return User.userLoadListSuccess({
            users,
          });
}),
        catchError((error) => of(User.userLoadListError({ error }))),
      );
    }),
  ));

  constructor(
    private actions$: Actions<User.UserActionsUnion>,
    private userService: UserService,
  ) {}
}
