import { Routes } from '@angular/router';

import { authRoutes } from './features/auth/auth-routing';
import { MoreInfoComponent } from './features/auth/views/more-info/more-info/more-info.component';
import { GameListComponent } from './features/discover/game/list/game-list.component';
import { ErrorPageComponent } from './features/error-page/error-page.component';
import { MainHomeComponent } from './features/main/views/home.component';
import { AppSearchComponent } from './features/search/search.component';
import { GameInfoResolver } from './route-resolvers';
import { AuthGuard } from './shared/guard/auth.guard';
import { NewUserGuard } from './shared/guard/new-user.guard';

export const appRoutes: Routes = [
  ...authRoutes,
  {
    path: 'discover/games',
    loadComponent: () =>
      import('./features/search/search.component')
        .then((c) => c.AppSearchComponent),
  },
  {
    path: 'discover/games/create',
    component: GameListComponent,
  },
  {
    path: 'home',
    component: MainHomeComponent,
    canActivate: [NewUserGuard],
  },
  {
    path: 'discover/games/:id',
    loadComponent: () =>
      import('./features/discover/game/view/game-view.component')
        .then((m) => m.GameViewComponent),
    resolve: {
      gameData: GameInfoResolver,
    },
  },
  {
    path: 'evt/:id',
    loadComponent: () =>
      import('./features/discover/game/view/game-view.component')
        .then((m) => m.GameViewComponent),
    resolve: {
      gameData: GameInfoResolver,
    },
  },
  {
    path: 'discover/games/:id/manage',
    loadComponent: () => import('./features/discover/game/manage/game-manage.component')
      .then((m) => m.GameManageComponent),
    resolve: {
      gameData: GameInfoResolver,
    },
  },
  {
    path: 'discover/players',
    loadComponent: () =>
      import('./features/search/search.component')
        .then((c) => c.AppSearchComponent),
  },
  {
    path: 'create',
    loadChildren: () =>
      import('./features/event-item/views/create-game/routes'),
    canActivate: [NewUserGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./features/admin/admin.module')
        .then((m) => m.AdminComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'custom-website',
    loadChildren: () =>
      import('./features/custom-website/custom-website.module')
        .then((m) => m.CustomWebsiteModule),
  },
  {
    path: 'discover/teams',
    loadComponent: () =>
      import('./features/search/search.component')
        .then((c) => c.AppSearchComponent),
  },
  {
    path: 'more-info',
    component: MoreInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    component: MoreInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'messages',
    loadChildren: () =>
      import('./features/messages/messages.module').then((mod) => mod.MessagesComponentModule),
    canActivate: [NewUserGuard],
  },
  {
    path: 'invoices',
    loadComponent: () => import('./features/invoices/invoices.component')
      .then((m) => m.InvoicesComponent),
    canActivate: [NewUserGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./features/profile/containers/profile-container/profile-container.module')
        .then((mod) => mod.ProfileContainerModule),
    canActivate: [NewUserGuard],
  },
  {
    path: 'team',
    loadChildren: () =>
      import('./features/teams/views/team-website/team-website.module')
        .then((m) => m.TeamWebsiteModule),
  },
  {
    path: 'external-teams',
    loadChildren: () =>
      import('./features/teams/views/external-teams/external-teams.module')
        .then((mod) => mod.AppExternalTeamsModule),
    canActivate: [NewUserGuard],
  },
  {
    path: 'teams',
    loadChildren: () =>
      import('./features/teams/teams.module').then((mod) => mod.TeamsComponentModule),
    canActivate: [NewUserGuard],
  },
  {
    path: 'payment-success',
    // eslint-disable-next-line max-len
    loadComponent: () => import('../../../gth-legacy/src/lib/components/payment-success/payment-success.component')
      .then((m) => m.PaymentSuccessComponent),
    canActivate: [NewUserGuard],
  },
  {
    path: 'transactions',
    loadComponent: () => import('./features/transactions/transactions.component')
      .then((m) => m.TransactionsComponent),
    canActivate: [NewUserGuard],
  },
  {
    path: '**',
    component: ErrorPageComponent,
  },
];
