import { Component } from '@angular/core';
import { APP_ROUTES } from '@shared/helpers';

@Component({
  selector: 'app-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.scss'],
})
export class AppWelcomeDialogComponent {
  bugFeedbackUri = APP_ROUTES.NewBug;
  feedbackForm = APP_ROUTES.NewFeature;
}
