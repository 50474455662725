import { CurrentState } from '@gth-legacy';
import { createAction, props } from '@ngrx/store';
import { GthEventItemModel } from '@sentinels/models';

export enum GameListActionTypes {
    EventItemsLoad = '[EventItems List Page] EventItemsLoad',
    EventItemsLoadSuccess = '[EventItems List Page] EventItems Load Success',
    ChangeStatus = '[EventItems List Page] Change Status',
}

export const LoadEventItems = createAction(
    GameListActionTypes.EventItemsLoad,
    props<{ lat: number, lng: number}>(),
);

export const OnEventItemSuccess = createAction(
  GameListActionTypes.EventItemsLoadSuccess,
    props<{ events: GthEventItemModel[] }>(),
);

export const ChangeStatus = createAction(
  GameListActionTypes.ChangeStatus,
    props<{ status: CurrentState}>(),
);
