<mat-card appearance="outlined" class="container">
  <h1>Payment Methods</h1>
  <mat-card appearance="outlined" class="account-card">
    <mat-grid-list cols="3" *ngIf="account$ | async">
      <mat-grid-tile>Stripe</mat-grid-tile>
      <mat-grid-tile>test&#64;test.com</mat-grid-tile>
      <mat-grid-tile>
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-grid-tile>
    </mat-grid-list>
    <ng-container *ngIf="account$ | async; else stripeCardTmpl"></ng-container>

    <ng-template #stripeCardTmpl>
      <stripe-card #stripeCard class="stripe-card" [(token)]="token" (tokenChange)="$event"></stripe-card>
      <button mat-raised-button type="button" class="token-button" (click)="onClick(stripeCard)">createToken
      </button>
    </ng-template>
  </mat-card>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="onDelete()">
    <span>Delete account</span>
  </button>
</mat-menu>


