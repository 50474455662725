<div class="container"> 
  <h2 mat-dialog-title>How to Add DUPR ID</h2>
  <div mat-dialog-content>
    <ol>
      <li>Go to <a href="https://mydupr.com" target="_blank" rel="noopener noreferrer">myDupr.com</a> and log in. If you are already logged in, you should see the dashboard link, and you can click that instead.</li>
      <li>To the right of your doubles and singles ratings, find and click the share button icon. Then click the copy to clipboard button (On mobile, it might be slightly above the ratings section).</li>
      <li>Paste the entire string in the "DUPR ID" field below and click "Update DUPR Rating."</li>
      <li>Go to your profile to see your updated score (You may need to refresh the page).</li>
    </ol>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="closeDialog()">Close</button>
  </div>
</div>