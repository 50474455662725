import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, EventEmitter, Component, Output, Input, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
function StripeSource_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 1);
    i0.ɵɵtext(2, "Stripe PublishableKey NOT SET. Use method StripeScriptTag.setPublishableKey()");
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
}
function StripeCard_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 1);
    i0.ɵɵtext(2, "Stripe PublishableKey NOT SET. Use method StripeScriptTag.setPublishableKey()");
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
}
function StripeBank_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 1);
    i0.ɵɵtext(2, "Stripe PublishableKey NOT SET. Use method StripeScriptTag.setPublishableKey()");
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
}
const STRIPE_PUBLISHABLE_KEY = new InjectionToken('Stripe Publishable Key');
const STRIPE_OPTIONS = new InjectionToken('Stripe Options');
class StripeScriptTag {
  constructor(document, key, options) {
    this.document = document;
    this.src = "https://js.stripe.com/v3/";
    this.window = this.document.defaultView;
    this.load = this.injectIntoHead();
    if (key) this.setPublishableKey(key, options);
  }
  promiseStripe() {
    return this.load;
  }
  promiseInstance() {
    return this.promiseStripe().then(stripe => {
      if (!this.StripeInstance) {
        const err = new Error("Stripe PublishableKey NOT SET. Use method StripeScriptTag.setPublishableKey()");
        err["code"] = "STRIPEKEYNOTSET";
        throw err;
        //return Promise.reject( err )
      }
      return this.StripeInstance;
    });
  }
  setPublishableKey(key, options) {
    return this.load.then(() => this.StripeInstance = this.Stripe(key, options));
  }
  injectIntoHead() {
    if (this.window && this.window["Stripe"]) {
      return Promise.resolve(this.Stripe = this.window["Stripe"]);
    }
    return new Promise((res, rej) => {
      const head = this.getTargetTagDropElement();
      const script = this.document.createElement("script");
      script.setAttribute("src", this.src);
      script.setAttribute("type", "text/javascript");
      script.addEventListener("load", () => {
        this.Stripe = this.grabStripe();
        res(this.Stripe);
      });
      head.appendChild(script);
    });
  }
  grabStripe() {
    return window["Stripe"];
  }
  getTargetTagDropElement() {
    let elm = this.document.getElementsByTagName("head");
    if (elm.length) return elm[0];
    return this.document.getElementsByTagName("body")[0];
  }
}
StripeScriptTag.ɵfac = function StripeScriptTag_Factory(t) {
  return new (t || StripeScriptTag)(i0.ɵɵinject(DOCUMENT), i0.ɵɵinject(STRIPE_PUBLISHABLE_KEY), i0.ɵɵinject(STRIPE_OPTIONS));
};
StripeScriptTag.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: StripeScriptTag,
  factory: StripeScriptTag.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StripeScriptTag, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [STRIPE_PUBLISHABLE_KEY]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [STRIPE_OPTIONS]
      }]
    }];
  }, null);
})();
class StripeComponent {
  constructor(StripeScriptTag) {
    this.StripeScriptTag = StripeScriptTag;
    this.catcher = new EventEmitter();
    this.invalidChange = new EventEmitter();
  }
  ngOnInit() {
    this.init();
  }
  init() {
    return this.StripeScriptTag.promiseInstance().then(i => this.stripe = i);
  }
}
StripeComponent.ɵfac = function StripeComponent_Factory(t) {
  return new (t || StripeComponent)(i0.ɵɵdirectiveInject(StripeScriptTag));
};
StripeComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: StripeComponent,
  selectors: [["stripe-component"]],
  inputs: {
    invalid: "invalid"
  },
  outputs: {
    catcher: "catch",
    invalidChange: "invalidChange"
  },
  decls: 0,
  vars: 0,
  template: function StripeComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StripeComponent, [{
    type: Component,
    args: [{
      selector: "stripe-component",
      template: ``
    }]
  }], function () {
    return [{
      type: StripeScriptTag
    }];
  }, {
    catcher: [{
      type: Output,
      args: ["catch"]
    }],
    invalid: [{
      type: Input
    }],
    invalidChange: [{
      type: Output
    }]
  });
})();
class StripeSource extends StripeComponent {
  constructor(StripeScriptTag) {
    super(StripeScriptTag);
    this.StripeScriptTag = StripeScriptTag;
    this.sourceChange = new EventEmitter();
    this.paymentMethodChange = new EventEmitter();
  }
  createSource(extraData) {
    delete this.invalid;
    this.invalidChange.emit(this.invalid);
    return this.stripe.createSource(this.elements, extraData).then(result => this.processSourceResult(result));
  }
  processSourceResult(result) {
    if (result.error) {
      const rError = result.error;
      if (rError.type === "validation_error") {
        this.invalidChange.emit(this.invalid = rError);
      } else {
        this.catcher.emit(rError);
        throw rError;
      }
    }
    const source = result.source;
    if (source) {
      this.sourceChange.emit(this.source = source);
      return source;
    }
  }
  createPaymentMethod(extraData) {
    delete this.invalid;
    this.invalidChange.emit(this.invalid);
    return this.stripe.createPaymentMethod('card', this.elements, extraData).then(result => this.processPaymentMethodResult(result));
  }
  processPaymentMethodResult(result) {
    if (result.error) {
      const rError = result.error;
      if (rError.type === "validation_error") {
        this.invalidChange.emit(this.invalid = rError);
      } else {
        this.catcher.emit(rError);
        throw rError;
      }
    }
    const paymentMethod = result.paymentMethod;
    if (paymentMethod) {
      this.paymentMethodChange.emit(this.paymentMethod = paymentMethod);
      return paymentMethod;
    }
  }
}
StripeSource.ɵfac = function StripeSource_Factory(t) {
  return new (t || StripeSource)(i0.ɵɵdirectiveInject(StripeScriptTag));
};
StripeSource.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: StripeSource,
  selectors: [["stripe-source"]],
  inputs: {
    source: "source",
    paymentMethod: "paymentMethod"
  },
  outputs: {
    sourceChange: "sourceChange",
    paymentMethodChange: "paymentMethodChange"
  },
  exportAs: ["StripeSource"],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 1,
  vars: 1,
  consts: [[4, "ngIf"], [2, "color", "red"]],
  template: function StripeSource_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, StripeSource_ng_container_0_Template, 3, 0, "ng-container", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", !ctx.StripeScriptTag.StripeInstance);
    }
  },
  dependencies: [i2.NgIf],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StripeSource, [{
    type: Component,
    args: [{
      selector: "stripe-source",
      template: `
      <ng-container *ngIf="!StripeScriptTag.StripeInstance">
          <div style="color:red;">Stripe PublishableKey NOT SET. Use method StripeScriptTag.setPublishableKey()</div>
      </ng-container>
  `,
      exportAs: "StripeSource"
    }]
  }], function () {
    return [{
      type: StripeScriptTag
    }];
  }, {
    source: [{
      type: Input
    }],
    sourceChange: [{
      type: Output
    }],
    paymentMethod: [{
      type: Input
    }],
    paymentMethodChange: [{
      type: Output
    }]
  });
})();
class StripeCard extends StripeSource {
  constructor(ElementRef, StripeScriptTag) {
    super(StripeScriptTag);
    this.ElementRef = ElementRef;
    this.StripeScriptTag = StripeScriptTag;
    this.tokenChange = new EventEmitter();
    this.cardMounted = new EventEmitter();
    this.complete = false;
    this.completeChange = new EventEmitter();
    this.changed = new EventEmitter();
    this.drawn = false;
  }
  ngOnInit() {
    super.init().then(() => this.redraw());
  }
  ngOnChanges(changes) {
    if (this.drawn && (changes.options || changes.createOptions)) {
      this.redraw();
    }
  }
  redraw() {
    if (this.drawn) {
      this.elements.unmount();
      this.elements.destroy();
    }
    this.elements = this.stripe.elements(this.createOptions).create('card', this.options);
    this.elements.mount(this.ElementRef.nativeElement);
    this.cardMounted.emit(this.elements);
    this.elements.on('change', result => {
      this.changed.emit(result);
      if (result.complete || this.complete && !result.complete) {
        this.completeChange.emit(this.complete = result.complete);
      }
    });
    this.elements.addEventListener('change', result => {
      if (result.error) {
        this.invalidChange.emit(this.invalid = result.error);
      }
    });
    this.drawn = true;
  }
  createToken(extraData) {
    delete this.invalid;
    this.invalidChange.emit(this.invalid);
    return this.stripe.createToken(this.elements, extraData).then(result => {
      if (result.error) {
        if (result.error.type == "validation_error") {
          this.invalidChange.emit(this.invalid = result.error);
        } else {
          this.catcher.emit(result.error);
          throw result.error;
        }
      } else {
        this.tokenChange.emit(this.token = result.token);
        return result.token;
      }
    });
  }
}
StripeCard.ɵfac = function StripeCard_Factory(t) {
  return new (t || StripeCard)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(StripeScriptTag));
};
StripeCard.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: StripeCard,
  selectors: [["stripe-card"]],
  inputs: {
    createOptions: "createOptions",
    options: "options",
    token: "token",
    complete: "complete"
  },
  outputs: {
    tokenChange: "tokenChange",
    cardMounted: "cardMounted",
    completeChange: "completeChange",
    changed: "changed"
  },
  exportAs: ["StripeCard"],
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature],
  decls: 1,
  vars: 1,
  consts: [[4, "ngIf"], [2, "color", "red"]],
  template: function StripeCard_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, StripeCard_ng_container_0_Template, 3, 0, "ng-container", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", !ctx.StripeScriptTag.StripeInstance);
    }
  },
  dependencies: [i2.NgIf],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StripeCard, [{
    type: Component,
    args: [{
      selector: "stripe-card",
      template: `
      <ng-container *ngIf="!StripeScriptTag.StripeInstance">
          <div style="color:red;">Stripe PublishableKey NOT SET. Use method StripeScriptTag.setPublishableKey()</div>
      </ng-container>
  `,
      exportAs: "StripeCard"
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: StripeScriptTag
    }];
  }, {
    createOptions: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    token: [{
      type: Input
    }],
    tokenChange: [{
      type: Output
    }],
    cardMounted: [{
      type: Output
    }],
    complete: [{
      type: Input
    }],
    completeChange: [{
      type: Output
    }],
    changed: [{
      type: Output
    }]
  });
})();
class StripeBank extends StripeComponent {
  constructor(StripeScriptTag) {
    super(StripeScriptTag);
    this.StripeScriptTag = StripeScriptTag;
    this.tokenChange = new EventEmitter();
  }
  createToken(data) {
    delete this.invalid;
    this.invalidChange.emit(this.invalid);
    return this.stripe.createToken('bank_account', data).then(result => {
      if (result.error) {
        if (result.error.type == "validation_error") {
          this.invalidChange.emit(this.invalid = result.error);
        } else {
          this.catcher.emit(result.error);
          throw result.error;
        }
      } else {
        this.tokenChange.emit(this.token = result.token);
        return result.token;
      }
    });
  }
}
StripeBank.ɵfac = function StripeBank_Factory(t) {
  return new (t || StripeBank)(i0.ɵɵdirectiveInject(StripeScriptTag));
};
StripeBank.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: StripeBank,
  selectors: [["stripe-bank"]],
  inputs: {
    options: "options",
    token: "token"
  },
  outputs: {
    tokenChange: "tokenChange"
  },
  exportAs: ["StripeBank"],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 1,
  vars: 1,
  consts: [[4, "ngIf"], [2, "color", "red"]],
  template: function StripeBank_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, StripeBank_ng_container_0_Template, 3, 0, "ng-container", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", !ctx.StripeScriptTag.StripeInstance);
    }
  },
  dependencies: [i2.NgIf],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StripeBank, [{
    type: Component,
    args: [{
      selector: "stripe-bank",
      template: `
      <ng-container *ngIf="!StripeScriptTag.StripeInstance">
          <div style="color:red;">Stripe PublishableKey NOT SET. Use method StripeScriptTag.setPublishableKey()</div>
      </ng-container>
  `,
      exportAs: "StripeBank"
    }]
  }], function () {
    return [{
      type: StripeScriptTag
    }];
  }, {
    options: [{
      type: Input
    }],
    token: [{
      type: Input
    }],
    tokenChange: [{
      type: Output
    }]
  });
})();
const declarations = [StripeComponent, StripeSource, StripeCard, StripeBank];
class StripeModule {
  static forRoot(publishableKey, options) {
    return {
      ngModule: StripeModule,
      providers: [StripeScriptTag, {
        provide: STRIPE_PUBLISHABLE_KEY,
        useValue: publishableKey
      }, {
        provide: STRIPE_OPTIONS,
        useValue: options
      }]
    };
  }
}
StripeModule.ɵfac = function StripeModule_Factory(t) {
  return new (t || StripeModule)();
};
StripeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: StripeModule
});
StripeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StripeModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations,
      // providers: [ StripeScriptTag ],
      exports: [...declarations]
    }]
  }], null, null);
})();
/**
 * @deprecated Please import `StripeModule` directly
 */
const Module = StripeModule;

/**
 * Generated bundle index. Do not edit.
 */

export { Module, STRIPE_OPTIONS, STRIPE_PUBLISHABLE_KEY, StripeBank, StripeCard, StripeComponent, StripeModule, StripeScriptTag, StripeSource };
