import { Injectable } from '@angular/core';
import { Howl } from 'howler';

@Injectable({
  providedIn: 'root',
})
export class ChimeService {
  private initialized = false;
  soundPath = '/assets/sounds/message.mp3';

  play() {
    if (!this.initialized) return;
    const sound = this.createSound();
    sound.play();
  }

  createSound() {
    return new Howl({
      src: [this.soundPath],
    });
  }

  init() {
    this.initialized = true;
  }
}
