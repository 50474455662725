import { Component, OnDestroy, OnInit } from '@angular/core';
import { GthAuthService } from '@gth-legacy';
import { Subscription } from 'rxjs';

import { APP_ROUTES } from '../../shared/helpers';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit, OnDestroy {
  routes = APP_ROUTES;
  homeLinkRoute = APP_ROUTES.SignUp;

  private subscriptions = new Subscription();

  constructor(private readonly auth: GthAuthService) { }

  ngOnInit() {
    this.subscriptions.add(
      this.auth.userModel$?.subscribe((user) => {
        if (!user) {
          return;
        }
        this.homeLinkRoute = APP_ROUTES.DiscoverGames;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
}
