import { Inject, Injectable } from '@angular/core';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';

import { GTH_ENVIRONMENT, GthEnvironment } from '../tokens/environment-tokens';

@Injectable({
  providedIn: 'root',
})
export class DuprScraperService {
  constructor(@Inject(GTH_ENVIRONMENT) private config: GthEnvironment) { }

  async scrapeData(userId: string, id: string): Promise<any> {
    try {
      const env = this.getEnviroment();
      // eslint-disable-next-line max-len
      const uri = `https://us-central1-gametimehero-${env}.cloudfunctions.net/users-triggers-scrapeDuprData`;
      const callable = httpsCallableFromURL(getFunctions(), uri);

      // Call the callable function with the provided data (in this case, 'id')
      const result = (await callable({ id, userId })).data;

      try {
        return JSON.parse(result as string);
      } catch {
        return result;
      }
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  private getEnviroment(): string {
    return this.config.envName!;
  }
}
