import { EarnedBadge } from '@index/interfaces/earned-badge';

import { GthModel } from './model';

export class GthBadgeModel extends GthModel<EarnedBadge> {
  constructor(
    id: string,
    model: EarnedBadge,
  ) {
    super(id, model);
  }

  public get badge(): EarnedBadge {
    return this.model;
  }

  public get id() {
    return this._id;
  }

  public get earnedBy() {
    return this.model.earnedBy ?? [];
  }

  public get name() {
    return this.model.name ?? this.model.description ?? 'Unknown badge name';
  }

  public get description() {
    return this.model.description;
  }
  set description(val: string) {
    this.model.description = val;
  }

  public get image(): string {
    let fileId = this.model.image ?? this.id;
    if (this.id === 'NewUser') {
      this.description = 'New User';
      this.image = '33';
      fileId = '33';
    }
    return `/assets/badges/${fileId}_192x238.png`;
  }
  set image(val: string) {
    this.model.image = val;
  }
}
