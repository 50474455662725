import { inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Router } from '@angular/router';
import { User } from '@index/interfaces';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OnInitEffects } from '@ngrx/effects';
import { GthUserModel } from '@sentinels/models';
import { SrvBetaService } from '@sentinels/services';
import { UserService } from '@sentinels/services/firebase/user.service';
import { CurrentState } from '@sentinels/state/state';
import { APP_ROUTES } from '@shared/helpers';
import { getAnalytics, setUserProperties } from 'firebase/analytics';
import firebase from 'firebase/compat/app';
import { EMPTY, from, Observable, Observer, of, throwError, zip } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';

import { isOfflineForDatabase, isOnlineForDatabase, LoginError } from '../../../../../../gth-legacy/src/lib/services';
import {
  AuthActionTypes,
  AuthEmailLogin,
  AuthEmailLoginError, AuthEmailLoginSuccess, AuthGoogleLogin,
  AuthLoad,
  AuthLogout,
  AuthLogoutError,
  AuthLogoutSuccess,
  AuthProviderLogin,
  AuthProviderLoginError,
  AuthProviderLoginSuccess,
  AuthSuccess,
} from './actions';

function getAuthResponseErrorType(error: string) {
  let errorType = LoginError.Unknown;
  console.log(error);
  const message = error.toLowerCase();
  if (message.indexOf('a network autherror') >= 0) {
    errorType = LoginError.NetworkError;
  } else if (message.indexOf('the popup has been closed') >= 0) {
    errorType = LoginError.CancelledByUser;
  } else if (message.indexOf('the password is invalid') >= 0) {
    errorType = LoginError.InvalidUser;
  } else if (message.indexOf('email unverified') >= 0) {
    errorType = LoginError.NoPasswordSet;
  }
  return errorType;
}

@Injectable()
export class AuthEffects implements OnInitEffects {
  readonly WatchAuthChangesEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActionTypes.AuthInit),
      switchMap(() => this.initAuth$()),
    ),
  );
  readonly UpdateAuthChangesEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActionTypes.AuthUpdate),
      switchMap(() => this.initAuth$()),
    ),
  );
  readonly loadAuthEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(AuthLoad),
      switchMap(() => this.initAuth$()),
    ),
  );
  readonly logoutEffect$ = createEffect(() => this.actions$.pipe(
    ofType(AuthLogout),
    switchMap(() => this.logout$()),
    tap(() => {
      this.router.navigate([APP_ROUTES.Login]);
      console.log('logout effect');
    }),
  ));
  readonly googleLoginEffect$ = createEffect(() => this.actions$.pipe(
    ofType(AuthGoogleLogin),
    switchMap(() => {
      const provider = new firebase.auth.GoogleAuthProvider();
      return of(AuthProviderLogin({ provider }));
    }),
  ));
  readonly emailLoginEffect$ = createEffect(() => this.actions$.pipe(
    ofType(AuthEmailLogin),
    switchMap((action) => this.emailLogin$(action.email, action.password)),
  ));
  readonly authProviderLoginEffect$ = createEffect(() => this.actions$.pipe(
    ofType(AuthProviderLogin),
    switchMap((action) => this.providerLogin$(action.provider)),
  ));
  readonly authWatchOnlineStatusEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.AuthInit),
      tap(() => this.watchOnlineStatus()),
    ),
    { dispatch: false },
  );

  private userService = inject(UserService);
  private router = inject(Router);
  private afAuth = inject(AngularFireAuth);
  private afdb = inject(AngularFireDatabase);

  constructor(
    private actions$: Actions,
    private beta: SrvBetaService,
  ) { }

  ngrxOnInitEffects(): any {
    return { type: AuthActionTypes.AuthInit };
  }

  get authState$(
  ): Observable<firebase.User> {
    const authState = new Observable((observer: Observer<firebase.User>) => {
      this.afAuth.onAuthStateChanged(
        (user?: firebase.User | null) => {
          if (!user) return;
          observer.next(user);
        },
        (error: firebase.auth.Error) => observer.error(error),
        () => observer.complete(),
      );
    });
    return authState;
  }

  initAuth$() {
    return from(this.authState$).pipe(
      switchMap((user) => {
        const userObs$ = this.userService.getUser(user.uid, user as unknown as User);
        const hasLoggedIn$ = this.userService.exists(user.uid);
        return zip(userObs$, hasLoggedIn$);
      }),
      map(([userModel, hasLoggedIn]) => {
        const state = hasLoggedIn ? CurrentState.Success :
          CurrentState.Pending;
        if (hasLoggedIn) {
          this.initBetaAudiences(userModel.id);
        }
        return AuthSuccess({ user: userModel, state });
      }),
      catchError(() => EMPTY),
    );
  }

  logout$() {
    return from(this.authState$).pipe(
      first(),
      tap((user) => {
        const userStatusDatabaseRef = this.getStatusRef(user);
        userStatusDatabaseRef.set(isOfflineForDatabase);
        console.log('user logging out');
      }),
      switchMap(() => from(this.afAuth.signOut()).pipe(
        map(() => AuthLogoutSuccess()),
        catchError((error: unknown) => of(AuthLogoutError({ error }))),
      )),
    );
  }

  providerLogin$(provider: firebase.auth.AuthProvider) {
    const signIn$ = from(this.afAuth.signInWithPopup(provider));

    return signIn$.pipe(
      switchMap((providerUser) => {
        if (!providerUser || !providerUser.user || !providerUser.user.email) {
          throwError(() => new Error('No provider user'));
        }
        return this.userService.getUserByEmail$(providerUser.user.email);
      }),
      switchMap((user) => {
        if (!user) {
          return this.afAuth.user.pipe(
            map((user) => {
              const userObj = {
                uid: user.uid,
                displayName: user.displayName ?? '',
                email: user.email ?? '',
                photoURL: user.photoURL ?? '',
                emailVerified: user.emailVerified,
              };
              return new GthUserModel(user.uid, userObj as unknown as User);
            }),
          );
        }

        return of(user);
      }),
      map((user) => {
        const login = { success: true, newUser: !user?.hasAdditionalInfo, user };
        return AuthProviderLoginSuccess({ user, login });
      }),
      catchError((err) => {
        const login = { success: false, newUser: false, user: null };
        return of(AuthProviderLoginError({
          error: getAuthResponseErrorType(err.toString()),
          login,
        }));
      }),
    );
  }

  emailLogin$(email: string, password: string) {
    return from(this.afAuth.fetchSignInMethodsForEmail(email)).pipe(
      switchMap((results) => {
        if (!results.includes('email') && results.includes('google.com')) {
          return from(this.afAuth.sendPasswordResetEmail(email)).pipe(
            map(() => {
              return true;
            }),
          );
        }
        return of(false);
      }),
      switchMap((results) => {
        if (results === true) {
          return throwError(() => 'Email unverified && Social set');
        }
        return from(this.afAuth.signInWithEmailAndPassword(email, password));
      }),
      switchMap((providerUser) => {
        if (!providerUser || !providerUser.user || !providerUser.user.email) {
          return of(undefined);
        }
        return this.userService.getUserByEmail$(providerUser.user.email);
      }),
      map((user) => {
        return AuthEmailLoginSuccess({
          user,
          login: {
            success: true,
            newUser: !user || !user.hasAdditionalInfo,
            user,
          },
        });
      }),
      catchError((err) => {
        const errorType = getAuthResponseErrorType(err.toString());
        const login = { success: false, newUser: false, user: null, errorType };
        return of(AuthEmailLoginError({
          error: errorType,
          login,
        }));
      }),
    );
  }

  watchOnlineStatus() {
    this.afdb.database.ref('.info/connected').on('value', (snapshot) => {
      // If we're not currently connected, don't do anything.
      if (snapshot.val() == false) {
        return;
      }
      this.afAuth.onAuthStateChanged((user) => {
        if (user) {
          const userStatusDatabaseRef = this.getStatusRef(user);
          userStatusDatabaseRef.set(isOnlineForDatabase);
          userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase);
        }
      });
    });
  }

  getStatusRef(user: GthUserModel | firebase.User) {
    return this.afdb.database.ref('status/' + user.uid);
  }

  private async initBetaAudiences(userId: string) {
      try {
        const audiences = await this.beta.getUserBetas(userId);
        if (!audiences?.length) return;
        const userProps: any = {};
        audiences.forEach((audience) => {
          userProps[audience] = 'ON';
        });
        const analytics = getAnalytics();
        setUserProperties(analytics, userProps);
      } catch {
        console.error('error setting audience for beta');
      }
  }
}
