import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { EventRatingComponent } from './event-rating.component';

@NgModule({
  declarations: [EventRatingComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatButtonModule,
  ],
  exports: [EventRatingComponent],
})
export class EventRatingModule { }
