import { JsonPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, EventEmitter, inject, Input, model, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AvailabilityDay, TimeRange } from '@index/interfaces';
import { Subscription } from 'rxjs';

export interface DateRangeForm {
  isAvailable: FormControl<boolean>;
  timeRanges: FormArray<FormGroup<TimeRangeForm>>;
}

export interface TimeRangeForm {
  startTime: FormControl<string>;
  endTime: FormControl<string>;
}

@Component({
  selector: 'gth-legacy-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  standalone: true,
  imports: [
    NgIf, NgFor, JsonPipe,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeComponent implements OnInit, OnDestroy {
  @Input()
  showCheckbox = true;

  @Input()
  showRemoveButton = true;

  @Input()
  showAddTimeButton = true;

  @Input()
  textBetweenCheckboxAndInput = '';

  availabilityDay = model<AvailabilityDay>();

  @Output()
  removeButtonClick = new EventEmitter<void>();

  @Output()
  availabilityDayChange = new EventEmitter<AvailabilityDay>();

  @Output()
  dateRangeFormChange = new EventEmitter<FormGroup<DateRangeForm>>();

  dateRangeForm: FormGroup<DateRangeForm>;

  private subscriptions = new Subscription();

  get timeRanges() {
    return this.dateRangeForm.controls.timeRanges as FormArray<FormGroup<TimeRangeForm>>;
  }

  get isAvailable() {
    return this.dateRangeForm.controls.isAvailable as FormControl<boolean>;
  }

  private cdr = inject(ChangeDetectorRef);

  constructor() {
    effect(() => {
      if (!this.availabilityDay()) return;
      this.initializeForm(this.availabilityDay());
    });
  }

  ngOnInit() {
    this.initializeForm(this.availabilityDay());
  }

  private initializeForm(availability?: AvailabilityDay) {
    if (availability) {
      const timeRangesFormArray = new FormArray<FormGroup<TimeRangeForm>>([]);
      for (const timeRange of availability.timeRanges) {
        timeRangesFormArray.push(this.newTimeRange(timeRange));
      }
      this.dateRangeForm = new FormGroup({
        isAvailable: new FormControl(availability.isAvailable ?? false),
        timeRanges: timeRangesFormArray,
      });
    } else {
      this.dateRangeForm = new FormGroup({
        isAvailable: new FormControl(
          false,
          { nonNullable: true, validators: Validators.required },
        ),
        timeRanges: new FormArray<FormGroup<TimeRangeForm>>([]),
      });
    }

    if (this.dateRangeForm) {
      this.dateRangeForm.validator = this.validateTimeRanges;
    }

    this.cdr.markForCheck();

    this.subscriptions.add(
      this.dateRangeForm.valueChanges.subscribe((availability) => {
        const userAvailability = availability as AvailabilityDay;
        this.availabilityDayChange.emit(userAvailability);
        this.dateRangeFormChange.emit(this.dateRangeForm);
      }),
    );
  }

  newTimeRange(timeRange?: TimeRange) {
    return new FormGroup<TimeRangeForm>({
      startTime: new FormControl(
        timeRange?.startTime ?? null,
        { nonNullable: true, validators: Validators.required },
      ),
      endTime: new FormControl(
        timeRange?.endTime ?? null,
        {
          nonNullable: true,
          validators: [Validators.required, this.endTimeValidator],
        },
      ),
    });
  }

  endTimeValidator(control: AbstractControl): ValidationErrors | null {
    const endTime = control.value;
    const startTime = control.parent?.get('startTime')?.value;
    if (endTime && startTime && endTime <= startTime) {
      return { endTimeBeforeStartTime: true };
    }
    return null;
  }

  validateTimeRanges: ValidatorFn = (
    control: AbstractControl,
  ): ValidationErrors | null => {
    const timeRanges = control.get('timeRanges') as FormArray<FormGroup<TimeRangeForm>>;
    const overlaps: boolean[] = [];
    for (let i = 0; i < timeRanges.length; i++) {
      for (let j = i + 1; j < timeRanges.length; j++) {
        const startTime1 = timeRanges.at(i).get('startTime')?.value;
        const endTime1 = timeRanges.at(i).get('endTime')?.value;
        const startTime2 = timeRanges.at(j).get('startTime')?.value;
        const endTime2 = timeRanges.at(j).get('endTime')?.value;
        if (
          (startTime1 <= startTime2 && startTime2 <= endTime1) ||
          (startTime1 <= endTime2 && endTime2 <= endTime1) ||
          (startTime2 <= startTime1 && startTime1 <= endTime2) ||
          (startTime2 <= endTime1 && endTime1 <= endTime2)
        ) {
          overlaps.push(true);
        } else {
          overlaps.push(false);
        }
      }
    }
    if (overlaps.some((overlap) => overlap)) {
      return { overlappingTimes: true };
    }
    return null;
  };

  addTimeRange(timeRange?: TimeRange) {
    this.timeRanges.push(this.newTimeRange(timeRange));
  }

  removeTimeRange(index: number) {
    this.timeRanges.removeAt(index);
    if (!this.timeRanges.length) this.isAvailable.setValue(false);
  }

  checkOverlap(index1: number, index2: number): boolean {
    const timeRanges = this.timeRanges.controls;
    const startTime1 = timeRanges[index1].get('startTime').value;
    const endTime1 = timeRanges[index1].get('endTime').value;
    const startTime2 = timeRanges[index2].get('startTime').value;
    const endTime2 = timeRanges[index2].get('endTime').value;

    return (startTime1 <= startTime2 && startTime2 <= endTime1) ||
      (startTime1 <= endTime2 && endTime2 <= endTime1) ||
      (startTime2 <= startTime1 && startTime1 <= endTime2) ||
      (startTime2 <= endTime1 && endTime1 <= endTime2);
  }

  onCheckboxChange(event: MatCheckboxChange) {
    const { checked } = event;

    if (checked) {
      if (!this.timeRanges.length) {
        const timeRange = {
          startTime: '17:00',
          endTime: '20:00',
        };
        this.addTimeRange(timeRange);
      }
    } else {
      this.timeRanges.clear();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
