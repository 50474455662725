import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TeamsService } from '@sentinels/services/firebase/teams.service';
import { LoadExternalTeamsByUserId, LoadExternalTeamsByUserIdError, LoadExternalTeamsByUserIdSuccess, LoadTeamRoles, LoadTeamRolesError, LoadTeamRolesSuccess, LoadTeamsByUserId, LoadTeamsByUserIdError, LoadTeamsByUserIdSuccess, loadTeamsListByLatLng, LoadTeamsListError, LoadTeamsListSuccess } from '@sentinels/state/features/teams/actions';
import { exhaustMap, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class TeamsEffects {
  constructor(
    private actions$: Actions,
    private teamsService: TeamsService,
  ) { }

  readonly loadTeamsByUserIdEffect$ = createEffect(() => this.actions$.pipe(
    ofType(LoadTeamsByUserId),
    mergeMap(({ userId }) => this.teamsService.getTeamsByUserId$(userId).pipe(
      map((teams) => LoadTeamsByUserIdSuccess({ teams })),
      catchError((error: unknown) => {
        return of(LoadTeamsByUserIdError({ error }));
      }),
    )),
  ));

  readonly loadExternalTeamsByUserIdEffect$ = createEffect(() => this.actions$.pipe(
    ofType(LoadExternalTeamsByUserId),
    exhaustMap((action) => this.getExternalTeamsByUserId$(action.userId)),
  ));

  readonly loadTeamRolesEffect$ = createEffect(() => this.actions$.pipe(
    ofType(LoadTeamRoles),
    exhaustMap(() => this.getTeamRoles$()),
  ));

  readonly loadTeamsListByLatLngEffect$ = createEffect(() => this.actions$.pipe(
    ofType(loadTeamsListByLatLng),
    exhaustMap((action) => this.getTeamsByLatLng$(action.lat, action.lng)),
  ));

  getTeamsByUserId$(userId: string) {
    return this.teamsService.getTeamsByUserId$(userId).pipe(
      map((teams) => LoadTeamsByUserIdSuccess({ teams })),
      catchError((error: unknown) => {
        return of(LoadTeamsByUserIdError({ error }));
      }),
    );
  }

  getExternalTeamsByUserId$(userId: string) {
    return this.teamsService.getExternalTeamsByUserId$(userId).pipe(
      map((externalTeams) => LoadExternalTeamsByUserIdSuccess({ externalTeams })),
      catchError((error: unknown) => {
        return of(LoadExternalTeamsByUserIdError({ error }));
      }),
    );
  }

  getTeamRoles$() {
    return this.teamsService.getTeamRoles$().pipe(
      map((roles) => LoadTeamRolesSuccess({ roles })),
      catchError((error: unknown) => {
        return of(LoadTeamRolesError({ error }));
      }),
    );
  }

  getTeamsByLatLng$(lat:number, lng: number) {
    return this.teamsService.getTeamsByLocation$(lat, lng).pipe(
      map((teams) => LoadTeamsListSuccess({ teams })),
      catchError((error: unknown) => {
        return of(LoadTeamsListError({ error }));
      }),
    );
  }
}
