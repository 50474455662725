<mat-card appearance="outlined">
  <header>
    <div>
      <h1>New Users Nearby</h1>
    </div>
  </header>

  <mat-card-content>
    <app-carousel>
      @for (user of users(); track user.uid) {
        <app-user-card
          [user]="user"
        ></app-user-card>
      }
      <app-user-card
        [generalCard]="true"
      ></app-user-card>
    </app-carousel>
  </mat-card-content>
</mat-card>