import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { environment } from '@environments/environment';
import { AccountSubscriptionType, StripeItemType } from '@sentinels/enums';
import { GthEventItemModel, GthStripeSubscriptionModel, GthTeamModel, GthUserModel } from '@sentinels/models';
import { EventItemService } from '@sentinels/services/firebase/event-items.service';
import { TeamRosterService } from '@sentinels/services/firebase/team-roster.service';
import { SrvSafeWindowService } from '@sentinels/services/safe-window.service';
import { APP_ROUTES } from '@shared/helpers';
import { Observable, Subscription } from 'rxjs';
import { first, map, switchMap, take } from 'rxjs/operators';
import { StripeModule } from 'stripe-angular';

import { ConnectStripeDialogComponent, ConnectStripeDialogOpenContract } from '../../dialogs/connect-stripe-dialog/connect-stripe-dialog.component';
import { PaymentDialogComponent, PaymentDialogContract } from '../../dialogs/payment-dialog/payment-dialog.component';
import { GthAuthService } from '../../services/auth.service';
import { GthRemoteConfigService } from '../../services/remote-config.service';
import { StripeService } from '../../services/stripe.service';
import { SubscriptionsService } from '../../services/subscriptions.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { GthStripeConnectedAccountComponent } from '../stripe-connected-account/stripe-connected-account.component';
import { StripeIconComponent } from '../stripe-icon/stripe-icon.component';
import { StripePricingTableComponent } from '../stripe-pricing-table/stripe-pricing-table.component';

@Component({
  selector: 'gth-user-payments',
  templateUrl: './user-payments.component.html',
  styleUrls: ['./user-payments.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    StripeModule,
    StripeIconComponent,
    GthStripeConnectedAccountComponent,
    MatButtonModule, MatDividerModule,
    MatIconModule, MatProgressSpinnerModule, StripePricingTableComponent,
  ],
})
export class UserPaymentsComponent implements OnInit, OnDestroy {
  @Input()
  platform: 'gth' | 'meh' = 'gth';

  user?: GthUserModel;
  user$?: Observable<GthUserModel>;
  teams$?: Observable<GthTeamModel[]>;
    events$?: Observable<GthEventItemModel[]>;
  subscriptionsEnabled = false;
  private subscriptions = new Subscription();
  stripeAuthorizationCode?: string;
  loading = true;
  protected readonly stripePublishableKey = environment.stripe;
  protected readonly envName = environment.envName as 'prod' | 'dev';


  constructor(
    private subscriptionsService: SubscriptionsService,
    private teamRosterService: TeamRosterService,
    private remoteConfig: GthRemoteConfigService,
    private safeWindow: SrvSafeWindowService,
    private eventsService: EventItemService,
    private stripeService: StripeService,
    private authService: GthAuthService,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.user$ = this.authService.userModel$;
    this.teams$ = this.user$.pipe(
      switchMap((user) => this.teamRosterService.getTeamsByUserId(user?.uid)),
      map((teams) => teams.map((team) => new GthTeamModel(team.id, team))),
    );
    this.events$ = this.user$.pipe(
      switchMap((user) => this.eventsService.list({ userId: user.uid })),
      map((events) => {
        const today = new Date();
        return events.filter((e) => e.dateStart && e.dateStart.getTime() > today.getTime());
      }),
    );
  }

  async ngOnInit(): Promise<void> {
    const param$ = this.route.queryParams;

    this.subscriptions.add(param$.subscribe(async (query) => {
      if (query.code) this.stripeAuthorizationCode = query.code;
      if (query.redirect_status === 'succeeded') {
        this.snackbar.open(
          'Congratulations, Payment was successful!',
          '',
          { duration: 5000 },
        );
        await this.router.navigate([], {
          queryParams: {
            redirect_status: null,
            payment_intent: null,
            payment_intent_client_secret: null,
          },
          queryParamsHandling: 'merge',
        });
      }

      const lifetimeCode = query.lifetime_code;
      if (lifetimeCode) console.debug('lifetime code => ', lifetimeCode);
      const user = this.user ?? await this.authService.userModel$.pipe(first()).toPromise();
      if (user?.userSubscription === 'Free' && lifetimeCode === 'EH450') {
        const lifetimeSubscription = new GthStripeSubscriptionModel(
          'LIFETIME',
          {
            id: 2,
            cost: 50,
            item: 'LIFETIME',
            label: 'Lifetime',
            uid: 'LIFETIME',
            features: [],
            priceId: environment.envName === 'local' ?
              'price_1ObT1REGIuJxcjmDyCXxfXZA' :
              'price_1ObcwREGIuJxcjmD7z7ypPtl',
          },
        );


        const contract: PaymentDialogContract = {
          type: StripeItemType.LIFETIME_USER_SUBSCRIPTION,
          subscription: lifetimeSubscription,
          userId: user.uid,
          platform: 'meh',
          lifetime: true,
        };
        this.dialog.open(PaymentDialogComponent, {
          minWidth: 360,
          data: contract,
        });
      }
    }));

    if (!this.mehPlatform) {
      this.subscriptionsEnabled = await this.remoteConfig.getSubscriptionsEnabled();
    }

    this.subscriptions.add(
      this.user$.subscribe((user) => {
        this.user = user;
        this.loading = false;
      }),
    );
  }

  onChangeTeamPlanBtnClick(team: GthTeamModel) {
    this.subscriptionsService.openSubscriptionDialog({
      subType: AccountSubscriptionType.TEAMS,
      team,
      platform: this.platform,
    });
  }

  onChangePickupPlanBtnClick() {
    this.changeUserSubscription(AccountSubscriptionType.DROPIN_PICKUP);
  }

  changeUserSubscription(subType: AccountSubscriptionType) {
    this.subscriptionsService.openSubscriptionDialog({
      subType,
      user: this.user,
      platform: this.platform,
    });
  }

  onStripeSetupButtonClick() {
    const connectStripeDialogContract: ConnectStripeDialogOpenContract = {
      platform: this.platform,
    };
    this.dialog.open(ConnectStripeDialogComponent, {
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog--custom-size',
      width: '550px',
      maxWidth: '100vw',
      height: '500px',
      data: connectStripeDialogContract,
    });
  }

  get mehPlatform() {
    return this.platform === 'meh';
  }

  onChangeUserSubscription(user: GthUserModel) {
    if ((this.mehPlatform ? user.userSubscription : user.subscription) === 'Free') {
      this.subscriptionsService.openSubscriptionDialog({
        subType: AccountSubscriptionType.USER,
        user,
        platform: this.platform,
      });
    } else {
      // this.onManageStripePortalBtnClick(user);
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        id: 'confirm-cancel-subscription-dialog',
        backdropClass: 'gth-overlay-backdrop',
        panelClass: 'gth-dialog',
        data: {
          title: `Cancel subscription?`,
          // eslint-disable-next-line max-len
          description: `Are you sure you would like to cancel your subscription?`,
          // eslint-disable-next-line max-len
          additionalInformation: 'It will take a couple of minutes for the changes to propagate.',
        },
      });

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .forEach(async (confirm) => {
          if (confirm) {
            const success = await this.stripeService.cancelSubscription(
              AccountSubscriptionType.USER,
              user.uid,
              this.platform,
            );
            if (success) {
              this.snackbar.open('Successfully cancelled subscription!', '', {
                duration: 5000,
              });
              if (this.platform === 'meh') {
                user.userSubscription = 'Free';
              } else {
                user.subscription = 'Free';
              }
            }
          }
        });
    }
  }

  onManageStripePortalBtnClick(user: GthUserModel) {
    const stripeCustomerPortalLink = environment.envName === 'prod' ?
      'https://billing.stripe.com/p/login/5kA2c136A2G4cZW4gg' :
      'https://billing.stripe.com/p/login/test_7sIdTT15A5Csb04288';
    // eslint-disable-next-line max-len
    const manageStripeLink = `${stripeCustomerPortalLink}?prefilled_email=${user.email}`;
    this.safeWindow.open(manageStripeLink);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  protected readonly APP_ROUTES = APP_ROUTES;
}
