import { createFeatureSelector, createSelector } from '@ngrx/store';

import { NavLayoutState } from './state';

export const selectNavLayoutState = createFeatureSelector<NavLayoutState>('navLayout');

export const isBottomNavCollapsed = createSelector(
    selectNavLayoutState,
    (state: NavLayoutState) => state.bottom.isCollapsed,
  );


