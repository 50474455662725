import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WeeklyAvailability } from '@index/interfaces';
import { GthUserModel, SrvAvailabilityModel } from '@sentinels/models';

import { AvailabilityDialogComponent } from './dialogs/availability-dialog.component';

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss'],
})
export class AppAvailabilityComponent {
  @Input()
  avail: boolean;

  @Input()
  user?: GthUserModel;

  @Input()
  day = '';

  @Input()
  srvModel: SrvAvailabilityModel;

  constructor(private dialog: MatDialog) { }

  get weeklyAvail() {
    return this.srvModel.weeklyAvailability;
  }

  openAvailabilityDialog() {
    this.dialog.open(AvailabilityDialogComponent, {
      data: {
        availability: this.srvModel,
        user: this.user,
        day: this.day,
      },
      width: '750px',
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog--custom-size',
    });
  }
}
