import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { LoadEventItems, OnEventItemSuccess } from './actions';
import { EventItemListService } from './services/events-list.service';

@Injectable()
export class EventItemListEffects {
      readonly effect1$ = createEffect(
          () => this.actions$.pipe(
              ofType(LoadEventItems),
              switchMap((action) => this.loadEvents$(action.lat, action.lng)),
          ),
      );

  constructor(
    private actions$: Actions,
    private EventItemListService: EventItemListService,
  ) {}

  loadEvents$(lat: number, lng: number) {
    return from(this.EventItemListService
    .getEventsByLatLng(lat, lng)).pipe(map((events)=>{
      return OnEventItemSuccess({ events });
    }),
    catchError((error) => {
      return EMPTY;
    }));
    // Todo(rkara): Lets devise a way to handle errors better.
  }
}
