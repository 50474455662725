import { CurrentState } from '@gth-legacy';
import { GthEventItemModel } from '@sentinels/models';

export interface EventItemsListState {
    events: GthEventItemModel[];
    state: CurrentState;
    error?: unknown;
}

export const initialState: EventItemsListState = {
    events: [],
    state: CurrentState.Pending,
  };
