import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { EventItemService } from '../../../services/firebase/event-items.service';
import * as Event from './actions';

@Injectable()
export class EventEffects {
  readonly loadEvent$ = createEffect(() => this.actions$.pipe(
    ofType(Event.EventActionTypes.EventLoadOne),
    mergeMap((action) =>{
      return this.eventService.getEvent(action.id).pipe(
        map((eventItem) => {
          return Event.eventLoadOneSuccess({ event: eventItem });
        }),
        catchError((error) => of(Event.eventLoadOneError({ error }))),
      );
    }),
  ));

  readonly loadAllEventsForUser$ = createEffect(() => this.actions$.pipe(
    ofType(Event.eventLoadListByUserId),
    mergeMap((action) =>{
      return this.eventService.getEventsForUser(action.id).pipe(
        map((obj) => {
          return Event.eventLoadListSuccess({
            events: obj.events,
            joiners: obj.joiners,
          });
        }),
        catchError((error) => of(Event.eventLoadListError({ error }))),
      );
    }),
  ));

  readonly loadAllEventsByLatLng$ = createEffect(() => this.actions$.pipe(
    ofType(Event.eventLoadListByLatLng),
    mergeMap((action) =>{
      return this.eventService.getEventsByLocation$(action.lat, action.lng).pipe(
        map((obj) => {
          return Event.eventLoadListSuccess({
            events: obj.events,
            joiners: obj.joiners,
          });
        }),
        catchError((error) => of(Event.eventLoadListError({ error }))),
      );
    }),
  ));

  constructor(
    private actions$: Actions<Event.EventActionsUnion>,
    private eventService: EventItemService,
  ) {}
}
