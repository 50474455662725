<mat-card appearance="outlined">
  <header>
    <div>
      <h2>
        <mat-icon>location_on</mat-icon>
        {{ cityName() }}
      </h2>
      <h1>Events Nearby</h1>
    </div>
    <a
      mat-raised-button
      color="accent" 
      [routerLink]="routes.CreateGame"
    >Create Your Own Event!</a>
  </header>
  <mat-card-content>
    <app-carousel>
      @for (obj of eventPlayerObjects(); track obj.event.id) {
        <app-event-card
          [event]="obj.event"
          [numberResponded]="obj.joiners.length"
          [numberNeeded]="obj.event.getPlayerCount(obj.joiners).totalNeeded"
          [participants]="obj.joiners"
        ></app-event-card>
      }
      <app-event-card
        [generalCard]="true"
      ></app-event-card>
    </app-carousel>
  </mat-card-content>
</mat-card>