import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { GthAuthService } from '@gth-legacy/services';
import { Conversation } from '@index/interfaces';
import { BehaviorSubject } from 'rxjs';

import { ChimeService } from './chime.service';

@Injectable({
  providedIn: 'root',
})
export class NewNotificationsService {
  subject = new BehaviorSubject<number>(0);
  loadedFirstNotifications = false;
  constructor(
    private auth: GthAuthService,
    private chime: ChimeService,
    private afdb: AngularFireDatabase,
  ) {
    this.auth.afAuth.onAuthStateChanged((user: any) => {
      if (!user) return;

      this.afdb.database.ref(`/conversations/${user.uid}/`).on('value', (snapshot) => {
        const data: { [key: string]: Conversation } = snapshot.val();
        let count = 0;
        if (!data) return;
        Object.keys(data).forEach((cKey) => {
          const conversation = data[cKey];

          if (!conversation || !conversation.messages) return;
          Object.entries(conversation.messages).forEach((keyValArr) => {
            const message = keyValArr[1];
            if (message.isRead === false && message.participantId !== user.uid) {
              count += 1;
            }
          });
        });
        if (count > 0 && this.loadedFirstNotifications) {
          this.chime.play();
        }
        this.loadedFirstNotifications = true;
        this.subject.next(count);
      });
    });
  }

  initSound() {
    this.chime.init();
  }
}
