<ng-container *ngIf="!loading else loadingTmpl">
  <section class="payment-method">
    <h3>Organizer Payouts</h3>
    <mat-divider />

    <gth-stripe-connected-account *ngIf="user?.stripeId || stripeAuthorizationCode else showPaymentMethod"
                                  [(user)]="user" [(stripeAuthorizationCode)]="stripeAuthorizationCode"
                                  [platform]="platform" />
    <ng-template #showPaymentMethod>
      <div class="payment-method-container">
        <div class="card-info-container">
          <picture>
            <mat-icon fontIcon="credit_card" />
          </picture>

          <div class="text-container">
            <strong class="email">Stripe ({{user?.email}})</strong>
            <span class="hint">Cover your court/field/room rental or Sell tickets with Stripe.</span>
          </div>

          <button mat-icon-button>
            <mat-icon fontIcon="more_horiz" />
          </button>
        </div>

        <mat-divider />

        <button mat-button color="accent" (click)="onStripeSetupButtonClick()">
          Start Stripe Setup
        </button>
      </div>
    </ng-template>
  </section>

  <section class="invoices">
    <button mat-raised-button type="button" [routerLink]="APP_ROUTES.Invoices">View Invoices</button>
    <button mat-button type="button" (click)="onManageStripePortalBtnClick(user)">Manage Stripe Portal</button>
  </section>

  <section *ngIf="!mehPlatform" class="transactions">
    <button mat-button type="button" [routerLink]="APP_ROUTES.Transactions">My Transactions</button>
  </section>

  <section class="subscription">
    <h3>User Subscription:
      {{(mehPlatform ? user?.userSubscription : user?.subscription) | json}}
    </h3>

    @if ((mehPlatform ? user?.userSubscription : user?.subscription) === "Free") {
      <gth-stripe-pricing-table [platform]="platform"
                                [customerEmail]="user?.email"
                                [publishableKey]="stripePublishableKey"
                                [envName]="envName" />
    } @else {
      <button mat-button color="primary" (click)="onChangeUserSubscription(user)">
        Cancel subscription
      </button>
    }
  </section>

  <section class="teams" *ngIf="subscriptionsEnabled">
    <h3>Subscriptions</h3>

    <mat-divider />

    <table class="more-info-table">
      <thead>
      <tr>
        <th>Name</th>
        <th>Current Plan</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>

      <ng-container *ngIf="teams$ | async as teams else loadingTmpl">
        <tr class="team-row" *ngFor="let team of teams">
          <td>{{team?.name}}</td>
          <td>{{team?.subscription}}</td>
          <td>
            <button mat-stroked-button class="action" color="primary" (click)="onChangeTeamPlanBtnClick(team)">
              Change Plan
            </button>
          </td>
        </tr>

        <tr class="empty-row">
          <td rowspan="3">
            <em *ngIf="!teams.length" class="team-row" [style.align-self]="'center'">
              You have no teams yet.
            </em>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </section>

  <section class="history">
    <h3>History</h3>

    <mat-divider />
  </section>
</ng-container>

<ng-template #loadingTmpl>
  <div class="fx-auto fx-fill fx-centered">
    <mat-spinner [diameter]="36" />
  </div>
</ng-template>
