import { JsonPipe } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { GthEventItemModel } from '@sentinels/models';

import { CarouselComponent } from '../carousel/carousel.component';
import { UpcomingEventItemComponent } from './upcoming-event-item/upcoming-event-item.component';

@Component({
  selector: 'app-home-upcoming-events',
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    UpcomingEventItemComponent,
    JsonPipe,
    CarouselComponent,
  ],
  templateUrl: './home-upcoming-events.component.html',
  styleUrl: './home-upcoming-events.component.scss',
})
export class HomeUpcomingEventsComponent {
  name = input<string>('Guest');
  events = input<GthEventItemModel[]>([]);

  get hasEvents() {
    return this.events() && this.events().length > 0;
  }

  get hasOneEvent() {
    return this.events() && this.events().length === 1;
  }
}
