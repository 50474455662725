import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { InstructionsDialogComponent } from './instructions-dialog.component';
import { MoreInfoSportsComponent } from './more-info-sports.component';
import { PersonalityTogglesComponent } from './personality-toggles/personality-toggles.component';
import { SportsTogglesModule } from './sports-toggles/sports-toggles.module';

const ANGULAR_MATERIAL_MODULES = [
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatProgressBarModule,
  ReactiveFormsModule,
];

const CORE_MODULES = [CommonModule, FormsModule];

const COMPONENTS = [MoreInfoSportsComponent, InstructionsDialogComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    ...CORE_MODULES, ...ANGULAR_MATERIAL_MODULES,
    SportsTogglesModule, PersonalityTogglesComponent,
  ],
  exports: [...COMPONENTS],
})
export class MoreInfoSportsModule { }
