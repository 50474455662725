import { Routes } from '@angular/router';
import { AuthDialogComponent } from '@shared/dialogs/auth-dialog/auth-dialog.component';
import { AuthGuard } from '@shared/guard/auth.guard';
import { APP_ROUTES } from '@shared/helpers';

import { AuthLayoutComponent } from './layout/auth-layout.component';
import { LoginComponent } from './views/login/login.component';
import { SignUpComponent } from './views/sign-up/sign-up.component';

export const authRoutes: Routes = [
  {
    path: 'auth',
    component: AuthDialogComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'sign-up',
        component: SignUpComponent,
      },
      {
        path: 'forgot-password',
        loadComponent: (() => import('./views/forgot-password/forgot-password.component')
          .then((m) => m.ForgotPasswordComponent)),
      },
    ],
  },
  {
    path: '',
    redirectTo: '/sign-up',
    pathMatch: 'full' as const,
  },
  {
    path: '',
    component: AuthLayoutComponent,
    canActivate: [AuthGuard],
    data: { authorizedRoute: APP_ROUTES.Home },
    children: [
      {
        path: 'login',
        loadComponent: (() => import('./views/login/login.component')
          .then((m) => m.LoginComponent)),
      },
      {
        path: 'sign-up',
        loadComponent: (() => import('./views/sign-up/sign-up.component')
          .then((m) => m.SignUpComponent)),
      },
      {
        path: 'forgot-password',
        loadComponent: (() => import('./views/forgot-password/forgot-password.component')
          .then((m) => m.ForgotPasswordComponent)),
      },
    ],
  },
];
