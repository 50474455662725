<mat-card appearance="outlined">
  <mat-card-header>
    <h1>Welcome back, {{ name() }}!</h1>
    <h2>
      <mat-icon>calendar_today</mat-icon>
      <p>
        You have
        <span>{{ events().length || '0' }}</span>
        upcoming event{{ !hasOneEvent ? 's' : '' }}
      </p>
    </h2>
  </mat-card-header>
  <mat-card-content>
    <app-carousel [hasControls]="false">
      @for (event of events(); track event.id) {
        <app-upcoming-event-item
          [event]="event"
        ></app-upcoming-event-item>
      }
      <app-upcoming-event-item
        [generalCard]="true"
      ></app-upcoming-event-item>
    </app-carousel>
  </mat-card-content>
</mat-card>
