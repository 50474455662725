import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { APP_ROUTES } from '@shared/helpers';
import { map } from 'rxjs';

import { GthAuthService, GthLoadingService } from '../../../../../gth-legacy/src/public-api';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  router = inject(Router);
  auth = inject(GthAuthService);
  spinner = inject(GthLoadingService);

  canActivate(route: ActivatedRouteSnapshot) {
    const unauthorizedRoute = route.data?.unauthorizedRoute;
    const authorizedRoute = route.data?.authorizedRoute;
    const user$ = this.auth.getAuthStateUser$();

    this.spinner.show = true;

    return user$.pipe(
      map((user: any) => {
        this.spinner.show = false;

        if (user === undefined) {
          return false;
        }

        if (user === null || user.email === null) {
          const routeToNavigate = unauthorizedRoute || (!authorizedRoute && APP_ROUTES.Login);
          if (routeToNavigate) {
            this.router.navigate([routeToNavigate]);
            return false;
          }
          return true;
        }

        if (authorizedRoute) {
          this.router.navigate([authorizedRoute]);
          return false;
        }

        return true;
      }),
    );
  }
}
