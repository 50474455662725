import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { JoinerService } from '../../../services/firebase/joiner.service';
import * as Joiner from './actions';

@Injectable()
export class JoinerEffects {
  readonly loadJoiner$ = createEffect(() => this.actions$.pipe(
    ofType(Joiner.joinerLoadList),
    mergeMap((action) =>
      this.joinerService.getJoiners(action.eventId).pipe(
        map((joiners) => {
          return Joiner.joinerLoadListSuccess({ joiners: joiners, eventId: action.eventId });
        }),
        catchError((error) => of(Joiner.joinerLoadListError({ error }))),
      ),
    ),
  ));

  constructor(
    private actions$: Actions<Joiner.JoinerActionsUnion>,
    private joinerService: JoinerService,
  ) {}
}
