<mat-table #table [dataSource]="dataSource" class="fx-auto" multiTemplateDataRows matSort>
  <!-- Game Title Column -->
  <ng-container matColumnDef="title">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Game Title </mat-header-cell>
    <mat-cell *matCellDef="let game" class="first-cell">
      <div class="games-table-name-container">
        <div class="image-container">
          <mat-icon>{{game?.gameTypeIcon}}</mat-icon>
        </div>

        <div class="name">
          <b>{{game?.title}}</b><br />
          <span class="secondary-text">By {{game?.creator?.displayName}}</span>
        </div>
      </div>
    </mat-cell>
  </ng-container>
  <!-- Type Column -->
  <ng-container matColumnDef="gameType">
    <mat-header-cell *matHeaderCellDef class="secondary-info" mat-sort-header> Type </mat-header-cell>
    <mat-cell *matCellDef="let game" class="secondary-info">
      <div class="field-container">
        <span class="column-main color-secondary">
          <b>{{ game.gameType }}</b>
        </span>
      </div>
    </mat-cell>
  </ng-container>
  <!-- Event Type Column -->
  <ng-container matColumnDef="eventType">
    <mat-header-cell *matHeaderCellDef class="tertiary-info" mat-sort-header> Event Type </mat-header-cell>
    <mat-cell *matCellDef="let game" class="tertiary-info">
      <div class="field-container">
        <span class="column-main color-secondary">
          <b>{{ game.eventType}} </b>
        </span>
      </div>
    </mat-cell>
  </ng-container>

  <!-- Date Column -->
  <ng-container matColumnDef="dateStart">
    <mat-header-cell *matHeaderCellDef class="tertiary-info" mat-sort-header> Date </mat-header-cell>
    <mat-cell *matCellDef="let game" class="tertiary-info">
      <div class="field-container">
        <span class="column-main color-secondary">
          <b>{{ game.dateStart | date: 'EEEE, MMMM d, y' }} </b>
        </span>
        <span class="column-secondary">
          At {{ game.dateStart | date: 'h:mm a' }}</span>
      </div>
    </mat-cell>
  </ng-container>

  <!-- Status Column -->
  <ng-container matColumnDef="Status">
    <mat-header-cell *matHeaderCellDef class="secondary-info"> Status </mat-header-cell>
    <mat-cell *matCellDef="let game" class="secondary-info">
      <div class="field-container">
        <ng-container *ngIf="getEventJoinerStatus(user, game) as status">
          <span class="column-main color-secondary" [matTooltip]="getEventJoinerStatusText(status)">
            {{ status }}
          </span>
        </ng-container>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="mobileDetail">
    <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
    <mat-cell *matCellDef="let game">
      <div class="field-container field-container--mobile">
        <ng-container *ngIf="getEventJoinerStatus(user, game) as status">
          <span class="column-main color-secondary mobile-status" [matTooltip]="getEventJoinerStatusText(status)">
            {{ status }}
          </span>
        </ng-container>
      </div>
    </mat-cell>
  </ng-container>

  <!-- Actions Column -->
  <ng-container matColumnDef="Actions">
    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
    <mat-cell *matCellDef="let game">
      <div class="action-cell fx-row fx-gap-10x fx-align-start">
        <button class="fx-auto" mat-stroked-button (click)="onViewGameButtonClick(game)"
          color="primary">
          View
        </button>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  <mat-row *matRowDef="let row; columns: ['mobileDetail']" class="mobile-detail-row"
    [class.mobile-detail-row--expanded]="getEventJoinerStatus(user, row)"
    [class.mobile-detail-row--approved]="getEventJoinerStatus(user, row) === 'Approved'"
    [class.mobile-detail-row--waitlisted]="getEventJoinerStatus(user, row) === 'Waitlisted'"
    [class.mobile-detail-row--pending-creator]="getEventJoinerStatus(user, row) === 'Pending Creator'"
    [class.mobile-detail-row--pending-approvers]="getEventJoinerStatus(user, row) === 'Pending Approvers'"
    [class.mobile-detail-row--dropped]="getEventJoinerStatus(user, row) === 'Dropped'"
    [class.mobile-detail-row--denied]="getEventJoinerStatus(user, row) === 'Denied'"></mat-row>
</mat-table>

<mat-paginator class="list-paginator" [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons
  aria-label="Select page of games">
</mat-paginator>