import { CurrentState } from '@gth-legacy';
import { createReducer, on } from '@ngrx/store';

import { ChangeStatus, LoadEventItems, OnEventItemSuccess } from './actions';
import { EventItemsListState, initialState } from './state';

export const eventItemListReducer = createReducer(
    initialState,
    on(
        LoadEventItems,
        (state: EventItemsListState) => ({ ...state, state: CurrentState.Loading }),
    ),

    on(
        OnEventItemSuccess,
        (state: EventItemsListState, action) => {
            return {
                ...state,
                state: CurrentState.Success,
                events: [...action.events] };
        },
    ),
    on(
        ChangeStatus,
        (state: EventItemsListState, action) => {
            return {
                ...state,
                state: action.status,
            };
        },
    ),
  );
