import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import {
    GthGoogleMapModule,
    GthOnboardingStepModule,
    TemplateTooltipDirective,
} from '@gth-legacy';
import { AppSearchBarComponentModule } from '@shared/components/search-bar/search-bar.module';
import { SendRequestDialogComponent } from '@shared/dialogs/send-request-dialog/send-request-dialog.component';
import { NavBarLayoutComponent } from '@shared/layouts/nav-bar-layout/views/nav-bar.component';

import { ArkUserAvatarComponent } from '../../../../../../ark/src/public-api';
import { AppAvailabilityComponent } from '../../settings/availability/availability.component';
import { CalendarComponentModule } from '../../settings/availability/calendar/calendar.module';
import { AvailabilityDialogModule } from '../../settings/availability/dialogs/availability-dialog.module';
import { PlayerListComponent } from './player-list.component';

@NgModule({
    imports: [
        CommonModule,
        AvailabilityDialogModule,
        CalendarComponentModule,
        NavBarLayoutComponent,
        MatButtonModule,
        MatCardModule,
        MatChipsModule,
        MatDialogModule,
        MatDividerModule,
        MatMenuModule,
        MatIconModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTableModule,
        MatTooltipModule,
        MatExpansionModule,
        TemplateTooltipDirective,
        GthGoogleMapModule,
        AppSearchBarComponentModule,
        GthOnboardingStepModule,
        RouterModule,
        SendRequestDialogComponent,
        MatSortModule,
        ArkUserAvatarComponent,
    ],
    exports: [PlayerListComponent],
    declarations: [PlayerListComponent, AppAvailabilityComponent],
})
export class PlayerListComponentModule { }
