import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { StripeCard } from 'stripe-angular';

import { PaymentsService } from './payments.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit {
  account$!: Observable<any>;
  token = undefined;
  paymentForm: UntypedFormGroup;
  stripStyles: any;

  constructor(readonly service: PaymentsService) { }

  ngOnInit(): void {
    this.account$ = this.service.getAccount$();
    this.stripStyles = {
      'style': {
        'base': {
          'iconColor': 'gold',
          'color': 'gold',
          'fontWeight': '500',
          'fontFamily': 'Roboto, Open Sans, Segoe UI, sans-serif',
          'fontSize': '16px',
          '::placeholder': {
            'color': 'gold',
          },
        },
      },
    };
  }

  async onClick(stripeCard: StripeCard) {
    await stripeCard.createToken();
    this.service.createAccount(this.token);
  }

  onDelete() {
    this.service.deleteAccount();
  }
}
