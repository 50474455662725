import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { AppWelcomeDialogComponent } from './welcome-dialog.component';

const ANGULAR_MATERIAL_MODULES = [
  MatDialogModule, MatFormFieldModule, MatInputModule,
  MatButtonModule,
];

const CORE_MODULES = [CommonModule, FormsModule];

const COMPONENTS = [AppWelcomeDialogComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...CORE_MODULES, ...ANGULAR_MATERIAL_MODULES],
  exports: [...COMPONENTS],
})
export class AppWelcomeDialogModule { }
