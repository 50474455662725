import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, computed, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { ArkSearchBarFilter, ArkSearchBarFilterContract } from '@ark';
import { GthAuthService, GthOnboardingService, GthOnboardingStep } from '@gth-legacy';
import { DefaultCity, WeeklyAvailability } from '@index/interfaces';
import { GthUserModel, SrvAvailabilityModel } from '@sentinels/models';
import { SrvUserAvailabiiltyService } from '@sentinels/services';
import { UserService } from '@sentinels/services/firebase/user.service';
import { SendRequestDialogComponent } from '@shared/dialogs/send-request-dialog/send-request-dialog.component';
import { NavBarOptions } from '@shared/layouts/nav-bar-layout/views/nav-bar.component';
import moment from 'moment-timezone';
import { BehaviorSubject, combineLatest, forkJoin, from, Observable, of, Subscription } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';

import { APP_ROUTES } from '../../../shared/helpers';
import { AvailabilityDialogComponent } from '../../settings/availability/dialogs/availability-dialog.component';


interface MatchingUserAvail {
  [key: string]: WeeklyAvailability
}

// eslint-disable-next-line max-len
const COLUMNS = ['displayName', 'Activities', 'Action'];

@Component({
  selector: 'app-players',
  templateUrl: './player-list.component.html',
  styleUrls: ['./player-list.component.scss'],
})
export class PlayerListComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator)
  private paginator?: MatPaginator;

  @ViewChild(MatSort)
  private sort!: MatSort;

  get OnboardingStep() {
    return GthOnboardingStep;
  }

  showMap = false;
  lat = -34.5;
  lng = 34.5;
  loading = false;
  user$?: Observable<GthUserModel | undefined>;
  defaultCity$?: Observable<{ city: DefaultCity | undefined }>;
  dataSource$?: Observable<MatTableDataSource<GthUserModel>>;
  navBarOptions: NavBarOptions = {
    pageName: 'Players',
  };
  noPlayers = true;
  displayOnboardingStep = false;
  displayMockResults = false;
  matchingAvail$: Observable<MatchingUserAvail> = of({});

  private subscription = new Subscription();
  // eslint-disable-next-line max-len
  private filterSettingsSubject = new BehaviorSubject<ArkSearchBarFilter | undefined>(undefined);
  private dirtySubject = new BehaviorSubject<boolean>(true);
  private playersCache?: GthUserModel[];
  get APP_ROUTES() {
    return APP_ROUTES;
  }

  private breakpointObserver = inject(BreakpointObserver);

  constructor(
    private onboarding: GthOnboardingService,
    private auth: GthAuthService,
    public dialog: MatDialog,
    private router: Router,
    private snackbar: MatSnackBar,
    public availService: SrvUserAvailabiiltyService,
    private usersService: UserService,
  ) { }

  isSmallScreen = toSignal(this.breakpointObserver.observe('(max-width: 700px)').pipe(
    map((breakpointState) => breakpointState.matches),
  ));

  displayedColumns = computed(() =>
    this.isSmallScreen() ? ['displayName', 'Action'] : COLUMNS,
  );

  displayColumns2 = COLUMNS;

  async ngOnInit() {
    this.user$ = this.auth.userModel$;
    const user = await this.auth.getUserModelAsync();
    if (user) {
      // eslint-disable-next-line max-len
      this.displayOnboardingStep = !(await this.onboarding.hasCompletedStep(GthOnboardingStep.ParticipantsSearch));
    }
    const dirty$ = this.dirtySubject.asObservable();
    const filter$ = this.filterSettingsSubject.asObservable();

    this.defaultCity$ = this.user$.pipe(
      map((user: GthUserModel) => {
        if (!user) {
          return { city: undefined };
        }
        return { city: user.defaultCity };
      }),
    );

    const dataSource$ = combineLatest([dirty$, filter$]).pipe(
      switchMap(([dirty, filter]) => {
        if (!dirty && this.playersCache) {
          this.loading = false;
          return of(this.playersCache);
        } else {
          this.loading = true;
          this.displayMockResults = false;
          this.noPlayers = false;
          const players$: Observable<GthUserModel[]> = this.user$.pipe(
            switchMap((user) => {
              return this.getPlayers$(user, filter);
            }),
          );
          return players$.pipe(
            map((players) => {
              this.playersCache = players;
              if (!players) this.playersCache = [];
              this.loading = false;
              this.noPlayers = !this.playersCache || this.playersCache.length === 0;
              this.dirtySubject.next(false);
              return players;
            }),
          );
        }
      }),
    );

    this.matchingAvail$ = dataSource$.pipe(
      mergeMap((players) => {
        const observables = players.map((player) =>
          from(this.availService.read(player.uid)).pipe(
            map((avail) => ({ [player.uid]: avail?.weeklyAvailability })),
          ),
        );
        return forkJoin(observables).pipe(
          map((availArray) => availArray
            .reduce((acc, avail) => Object.assign(acc, avail), {})),
        );
      }),
    );

    this.dataSource$ = dataSource$.pipe(
      map((players) => {
        const dataSource = new MatTableDataSource(Object.assign([], players));
        if (this.paginator) {
          dataSource.paginator = this.paginator;
        }
        if (this.sort) {
          dataSource.sort = this.sort;
        }
        return dataSource;
      }),
    );
  }

  onSearchPerformed(evt: ArkSearchBarFilterContract) {
    if (this.displayMockResults) {
      // Reset query params to avoid user ending up with mock resutls again
      this.router.navigate([]);
    }
    this.filterSettingsSubject.next(evt.queryParams);
    this.dirtySubject.next(true);
  }

  onCreateButtonClick() {
    this.router.navigate([APP_ROUTES.Settings], {
      queryParams: {
        tab: 'availability',
      },
    });
  }

  private getPlayers$(
    user: GthUserModel,
    filterSettings: ArkSearchBarFilter | undefined,
  ): Observable<GthUserModel[] | undefined> {
    if (!filterSettings || !filterSettings.city) return of(undefined);
    const city = filterSettings.city;

    if (!city.lat || !city.lng) return of(this.playersCache);

    this.lat = city.lat;
    this.lng = city.lng;

    return from(this.usersService.list({ lat: this.lat, lng: this.lng }))
      .pipe(
        switchMap((playerList: any) => {
          if (!playerList) {
            return of([]);
          }
          const players: GthUserModel[] = playerList
            .filter((p) => user && p.uid !== user.uid);
          if (players.length) this.noPlayers = false;
          return of(players);
        }),
        mergeMap((players: GthUserModel[]) => {
          if (players.length === 0) {
            return of([]);
          }
          return forkJoin(players.map((player) => from(this.setNewAvail(player))));
        }),
        map((players: GthUserModel[]) => {
          return players
            .filter((player) => {
              if (filterSettings.gameType && filterSettings.gameType.length > 0) {
                return filterSettings.gameType.some((gameType) => {
                  const sports = player.additionalInfo.sports.includes(gameType);
                  if (sports) {
                    return true;
                  }
                  const availability = player.sportAvailability
                    .find((s) => s.sport === gameType);
                  if (availability) {
                    return true;
                  }
                  return false;
                });
              }
              return true;
            })
            .filter((player) => {
              if (!filterSettings.ratings || !filterSettings.ratings.length) {
                return true;
              }
              return filterSettings.ratings.some((filterRating) =>
                player.ratings.map((r) => r.rating).includes(filterRating),
              );
            })
            .filter((player) => {
              if (!filterSettings.days || !filterSettings.days.length) {
                return true;
              }
              return filterSettings.days.every((filterDay) => {
                  // eslint-disable-next-line max-len
                  return player.userAvailability?.weeklyAvailability[filterDay.toLowerCase()].isAvailable;
                },
              );
            })
            .filter((player) => {
              if (!filterSettings.exactDate) {
                return true;
              }

              if (!player.userAvailability) {
                return false;
              }

              const m1 = moment(filterSettings.exactDate);
              // eslint-disable-next-line max-len
              const filtered = player.userAvailability!.dateSpecificAvailability.filter((dateItem) => {
                const mDate2 = moment(dateItem.date).date();
                return m1.date() === mDate2 && !!dateItem.timeRanges.length;
              });

              if (filtered.length) {
                return true;
              }
              const userAvail = player.userAvailability!;
              const mDay = m1.format('dddd').toLowerCase();

              return userAvail.availableOnDay(mDay);
            });
        }),
        map((players) => {
          return players
            .filter((player) => player.userAvailability?.isAvailableInWeek) || [];
        }),
      );
  }

  async setNewAvail(player: GthUserModel) {
    const availability = await this.availService.read(player.uid);
    player.userAvailability = availability;
    return player;
  }

  displayAvailability(t: SrvAvailabilityModel, day?: string): boolean {
    const weeklyAvail = t.weeklyAvailability[day];
    return weeklyAvail ? weeklyAvail.isAvailable : false;
  }

  onMessageBtnClick(user: GthUserModel) {
    if (user.uid.startsWith('mock-')) {
      this.snackbar.open('Cannot send a message to a fake user.');
      return;
    }
    const navExtras: NavigationExtras = {
      queryParams: {
        userId: user.uid,
      },
    };
    this.router.navigate([APP_ROUTES.Messages], navExtras);
  }

  onSendRequestBtnClick(player: GthUserModel, user: GthUserModel) {
    this.dialog.open(SendRequestDialogComponent, {
      id: 'send-request-dialog',
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog--custom-size',
      width: '720px',
      height: '500px',
      data: {
        player,
        user,
      },
    });
  }

  openAvailabilityDialog(user: GthUserModel) {
    this.dialog.open(AvailabilityDialogComponent, {
      data: {
        availability: user.userAvailability,
        user: user,
        day: null,
      },
      width: '750px',
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog--custom-size',
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
