import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { InvoicesService } from '@sentinels/services/firebase/invoices.service';
import {
  LoadInvoicesByUserId, LoadInvoicesByUserIdError,
  LoadInvoicesByUserIdSuccess,
} from '@sentinels/state/features/invoices/actions';
import { exhaustMap, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class InvoicesEffects {
  constructor(
    private actions$: Actions,
    private invoicesService: InvoicesService,
  ) {}

  readonly loadInvoicesByUserIdEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoadInvoicesByUserId),
      exhaustMap((action) => this.getInvoicesByUserId$(action.userId)),
    );
  });

  getInvoicesByUserId$(userId: string) {
    return this.invoicesService.getInvoicesByUserId$(userId).pipe(
      map((invoices) => {
        return LoadInvoicesByUserIdSuccess({ invoices });
      }),
      catchError((error: unknown) => {
        return of(LoadInvoicesByUserIdError({ error }));
      }),
    );
  }
}
