<div class="fx-fill fx-column">
  <div class="fx-row action-row">
    <span class="spacer"></span>
    <button mat-flat-button color="primary" [matMenuTriggerFor]="menu">Sort</button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="onSortChange(1)" [class.--active]="sortType === 1">Sort by Title
        <mat-icon>arrow_upward</mat-icon>
      </button>
      <button mat-menu-item (click)="onSortChange(2)" [class.--active]="sortType === 2">
        Sort By Title
        <mat-icon>arrow_downward</mat-icon>
      </button>
      <button mat-menu-item (click)="onSortChange(3)" [class.--active]="sortType === 3">Sort by Date
        <mat-icon>arrow_upward</mat-icon>
      </button>
      <button mat-menu-item (click)="onSortChange(4)" [class.--active]="sortType === 4">
        Sort By Date
        <mat-icon>arrow_downward</mat-icon>
      </button>
    </mat-menu>
  </div>
  <mat-action-list class="fx-auto list">
    <button mat-list-item class="list-item" *ngFor="let event of sortedEvents" (click)="onViewGameButtonClick(event)">
      <mat-icon class="list-icon" matListItemIcon>{{ event.gameTypeIcon }}</mat-icon>

      <span matListItemTitle>{{ event.title }}</span>
      <span matListItemLine>
        {{ event.creator?.displayNameFallback }}
      </span>
      <span matListItemLine>
        {{ event.gameType }}
      </span>
      <span matListItemLine>
        {{ event.dateStart | date: 'EEEE, MMMM d, y' }} At {{ event.dateStart | date: 'h:mm a' }}
      </span>
    </button>
  </mat-action-list>
</div>