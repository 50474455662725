import { Injectable } from '@angular/core';
import { EarnedBadge } from '@index/interfaces/earned-badge';
import { GthBadgeModel } from '@sentinels/models';
import { FirestoreService } from '@sentinels/services/core/firebase.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BadgesService extends FirestoreService<EarnedBadge> {
  protected basePath = 'badges';

  getBadges$() {
    return this.list$().pipe(
      map((badges) => {
        return badges.map((b) => new GthBadgeModel(b.id, b));
    }),
    );
  }

  getBadgeById$(id: string) {
    return this.read$({ id }).pipe(
      map((b) => new GthBadgeModel(b.id, b)),
    );
  }

  read$(request: {id: string}): Observable<EarnedBadge | null> {
    const docRef = this.doc<EarnedBadge>(request.id);

    return docRef.get().pipe(
      map((badgeSnap) => {
        if (!badgeSnap) return null;

        return { id: badgeSnap.id, ...badgeSnap.data() };
      }),
    );
  }

  list$(): Observable<EarnedBadge[]> {
    return this.collection.get().pipe(
      map((badgesSnap) => {
        if (badgesSnap.empty) return [];

        return badgesSnap.docs.map((badgeSnap) => {
          return {
            id: badgeSnap.id,
            ...badgeSnap.data() as EarnedBadge,
          };
        });
      }),
    );
  }
}
