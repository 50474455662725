import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GthEventItemModel } from '@sentinels/models';
import { TeamsService } from '@sentinels/services/firebase/teams.service';
import { exhaustMap, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { LoadEvents, LoadEventsError, LoadEventsSuccess, SetActiveTeamById, SetActiveTeamByIdError, SetActiveTeamByIdSuccess } from './actions';

@Injectable()
export class ActiveTeamsEffects {
  constructor(
    private actions$: Actions,
    private teamsService: TeamsService,
  ) { }

  readonly setActiveTeamByTeamId$ = createEffect(() => this.actions$.pipe(
    ofType(SetActiveTeamById),
    exhaustMap((action) => this.teamsService.getTeamByTeamId$(action.activeTeamId)),
    map((activeTeam) => SetActiveTeamByIdSuccess({ activeTeam })),
    catchError((error: unknown) => {
      return of(SetActiveTeamByIdError({ error }));
    }),
  ));

  readonly loadEvents$ = createEffect(() => this.actions$.pipe(
    ofType(LoadEvents),
    exhaustMap((action) => this.teamsService.getEventsByTeamId$(action.activeTeamId)),
    map((events: GthEventItemModel[]) => LoadEventsSuccess({ events })),
    catchError((error: unknown) => {
      return of(LoadEventsError({ error }));
    }),
  ));
}
