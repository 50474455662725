<ng-container *ngIf="user$ | async as user">
  <div class="privacy-container">
    <div class="privacy-settings-container">
      <ul>
        <li>
          <mat-slide-toggle [id]="'messagesFromAnyone'" [checked]="user.privacySettings.messagesFromAnyone"
            (change)="onPrivacySettingsChange(user, $event)">
            Allow messages from anyone in the app
          </mat-slide-toggle>
        </li>
        <li *ngIf="displayBadges">
          <mat-slide-toggle [id]="'publicBadges'" [checked]="user.privacySettings.publicBadges"
            (change)="onPrivacySettingsChange(user, $event)">
            Publicly show badges
          </mat-slide-toggle>
        </li>
        <li>
          <mat-slide-toggle [id]="'publicOnlineStatus'" [checked]="user.privacySettings.publicOnlineStatus"
            (change)="onPrivacySettingsChange(user, $event)">
            Publicly show online status
          </mat-slide-toggle>
        </li>
        <li>
          <mat-slide-toggle [id]="'publicUpcomingGames'" [checked]="user.privacySettings.publicUpcomingGames"
            (change)="onPrivacySettingsChange(user, $event)">
            Publicly show upcoming events
          </mat-slide-toggle>
        </li>
      </ul>
    </div>

    <div class="block-list-container">
      <h2>Block List</h2>
      <ng-container *ngIf="blockedConversations$ | async as blockedConvos">
        <ng-container *ngIf="blockedConvos.length; else noBlockedConvos">
          <ul id="blockList">
            <li *ngFor="let convo of blockedConvos">
              <div class="user">
                <mat-icon>account_circle</mat-icon>
                {{convo.getParticipants(user)[0].displayName}}
              </div>
              <button mat-stroked-button color="warn" (click)="this.onUnblockButtonClick(user, convo)">Remove</button>
            </li>
          </ul>
        </ng-container>

        <ng-template #noBlockedConvos>
          <p>You have not blocked any players.</p>
        </ng-template>
      </ng-container>
    </div>
  </div>

  <div class="danger-zone-container">
    <h2>Danger Zone</h2>
    <a mat-flat-button [disabled]="(currentPlayerTeams$ | async) === null" class="inverted delete-account-button"
      type="button" color="accent" (click)="onDeleteUserClick(user)">
      Delete Your Account
    </a>
  </div>

  <a mat-flat-button class="inverted uppercase" type="button" color="accent" [routerLink]="[APP_ROUTES.Profile]">
    View Profile
  </a>
</ng-container>