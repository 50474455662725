import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterLink } from '@angular/router';
import { APP_STATE, eventLoadListByLatLng, eventLoadListByUserId, eventReducer } from '@gth-legacy';
import { Store } from '@ngrx/store';
import { GthUserModel } from '@sentinels/models';
import { SrvUserAvailabiiltyService } from '@sentinels/services';
import { selectUser } from '@sentinels/state/features/auth/selectors';
import { selectJoinersMappedwithEvents } from '@sentinels/state/features/joiners/selectors';
import { userLoadListByLatLng, userLoadOne } from '@sentinels/state/features/user/actions';
import { selectUsersByLatLngForAuthUser } from '@sentinels/state/features/user/selectors';
import { APP_ROUTES } from '@shared/helpers';
import { NavBarLayoutComponent } from '@shared/layouts/nav-bar-layout/views/nav-bar.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { ArkLocationCardComponent } from '../../../../../../ark/src/lib/components/location-card/location-card.component';
import { selectEventsByCreatorIdAndPlayerAsJoiner } from '../../profile/state/selectors';
import { NearbyEventsComponent } from '../components/nearby-events/nearby-events.component';
import { NearbyPlayersComponent } from '../components/nearby-players/nearby-players.component';
import { HomeUpcomingEventsComponent } from '../components/upcoming-events/home-upcoming-events.component';
import { AlertMenuComponent, TodoListItem } from '@shared/components/alert-menu/alert-menu.component';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatCardModule,
    NavBarLayoutComponent,
    NgxSkeletonLoaderModule,
    MatIconModule,
    MatListModule,
    RouterLink,
    MatExpansionModule,
    ArkLocationCardComponent,
    HomeUpcomingEventsComponent,
    NearbyEventsComponent,
    NearbyPlayersComponent,
    AlertMenuComponent
  ],
})
export class MainHomeComponent implements OnInit {
  tasks: TodoListItem[] = [];
  APP_ROUTES = APP_ROUTES;

  user = this.store.selectSignal(selectUser);
  userEvents = this.store.selectSignal(
    selectEventsByCreatorIdAndPlayerAsJoiner(this.user().id),
  );
  users = this.store.selectSignal(selectUsersByLatLngForAuthUser(0, 7));

  nearbyEventPlayerObjects = this.store.selectSignal(selectJoinersMappedwithEvents(0, 7));

  constructor(
    private store: Store<APP_STATE>,
    private availService: SrvUserAvailabiiltyService,
    private cdr: ChangeDetectorRef,
  ) {
    this.store.addReducer('event', eventReducer);
  }

  ngOnInit(): void {
    this.selectUserAndRunSideEffects();
  }

  async selectUserAndRunSideEffects() {
    if (!this.user()) return;
    const latLng = {
      lat: this.user().defaultCity.lat,
      lng: this.user().defaultCity.lng,
    };
    this.dispatchViaUserLocation(latLng);
    this.tasks = await this.getUserIncompleteTasks(this.user());
    this.cdr.markForCheck();
  }

  dispatchViaUserLocation(latLng: { lat: number, lng: number }) {
    this.store.dispatch(userLoadOne({ uid: this.user().id }));
    this.store.dispatch(eventLoadListByUserId({ id: this.user().id }));
    this.store.dispatch(eventLoadListByLatLng(latLng));
    this.store.dispatch(userLoadListByLatLng(latLng));
  }

  async getUserIncompleteTasks(user: GthUserModel) {
    const avail = await this.availService.read(user.id);
    const tasks = [];
    if (!avail) {
     tasks.push({
       text: 'Update your availibility so others can find you!',
       url: [APP_ROUTES.Settings],
       params: { tab: 'availability' },
     });
    }

    if (user.sportAvailability.length === 0) {
     tasks.push({
       text: 'Set your favorite activities to match with others!',
       url: [APP_ROUTES.Settings],
       params: { tab: 'sports' },
     });

     if (!user?.bio) {
      tasks.push({
        text: 'Complete your Bio!',
        url: [APP_ROUTES.Profile],
      });
    }
   }

   return tasks;
  }
}
