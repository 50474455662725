import { Component, Input, WritableSignal, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

export interface TodoListItem{
  text: string;
  url: any [];
  params: {[key: string]: any};
}

@Component({
  selector: 'app-alert-menu',
  standalone: true,
  imports: [MatButtonModule, MatExpansionModule, MatIconModule, RouterLink],
  templateUrl: './alert-menu.component.html',
  styleUrl: './alert-menu.component.scss'
})
export class AlertMenuComponent {
  @Input() tasks: TodoListItem[] = [];
}
