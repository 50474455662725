import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { NewNotificationsService } from './new-notifications.service';

function buildText(text: string, num: number) {
  if (num) {
    return `(${num}) ${text}`;
  }
  return text;
}

/* eslint-disable quote-props */
const titleDict: { [key: string]: string } = {
  teams: 'Teams | Gametime Hero',
  'more-info': 'Settings | Gametime Hero',
  games: 'Games | Gametime Hero',
  messages: 'Messages | Gametime Hero',
  profile: 'Profile | Gametime Hero',
  login: 'Gametime Hero - Log In and Start playing!',
  'sign-up': 'Gametime Hero - Sign up to join the fun!',
  default: 'Gametime Hero',
};
/* eslint-enable quote-props */

@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  public routeInfo: NavigationEnd = { url: '/default' } as NavigationEnd;
  public title$: BehaviorSubject<string>;

  constructor(public newNotifications: NewNotificationsService, private router: Router) {
    this.title$ = new BehaviorSubject<string>('Gametime Hero');

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.routeInfo = val;
        this.getTitle();
      }

      this.newNotifications.subject.subscribe(() => {
        this.getTitle();
      });
    });
  }

  getTitle() {
    const subject = this.newNotifications.subject;
    const title = this.routeInfo.url.split('/', 2).pop();
    if (title) {
      this.title$.next(
        titleDict[title] ? buildText(titleDict[title], subject.getValue()) : 'Gametime Hero',
      );
    }
  }
}
