<div class="fx-fill fx-column">
  <img src="assets/logo-standing-fullcolor.webp" class="logo" />
  <h1 mat-dialog-title class="dialog-title">Welcome to Gametime Hero!</h1>
  <div mat-dialog-content class="fx-auto dialog-content">
    <p>
      Welcome to the Gametime Hero Beta!
    </p>
    <p>
      We value your input.
    </p>
    <p>
      Please report bugs in our <a [href]="bugFeedbackUri" target="_blank">Google Sheet</a> and share your feedback in
      our <a [href]="feedbackForm" target="_blank">Google Form</a> and hold onto this
      links for future feedback.
    </p>
    <p>
      Thank you for helping us make this experience better!
    </p>
  </div>
  <div mat-dialog-actions class="fx-gap-10x fx-row dialog-actions">
    <button mat-flat-button class="fx-auto dialog-button" mat-dialog-close color="primary">
      Game on!
    </button>
  </div>
</div>