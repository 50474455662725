import { Injectable } from '@angular/core';
import { EventItem } from '@index/interfaces';
import { DBUtil } from '@index/utils/db-utils';
import { GthEventItemModel } from '@sentinels/models';
import { FirestoreService } from '@sentinels/services/core/firebase.service';
import { distanceBetween } from 'geofire-common';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EventItemListService extends FirestoreService<EventItem> {
  protected basePath: string = DBUtil.EventItem;

  /**
   * Get list of events within geographic location
   *
   * @param {number} lat Latitude of filter
   * @param {number} lng Longitude of filter
   * @return {Observable<GthEventItemModel[]>} List of events
   */
  getEventsByLatLng(lat: number, lng: number): Observable<GthEventItemModel[]> {
    /** Find cities within 200km of specified location */
    const radiusInM = 200 * 1000;
    const center: [number, number] = [lat, lng];

    if (!lat || !lng) {
      return of([]);
    }

    interface EventItemWithId extends EventItem {
      id: string;
    }
    // eslint-disable-next-line max-len
    const events$ = this.collection$(undefined, { idField: 'id' }) as unknown as Observable<EventItemWithId[]>;
    return events$.pipe(
      take(1),
      /** Filter events that within 200km of the specified location */
      map((events) => {
        const filtered = events.filter((event) => {
          if (!event?.location?.lat || !event?.location?.lng) return false;

          const distanceInKm = distanceBetween(
            [event.location.lat, event.location.lng],
            center,
          );
          const distanceInM = distanceInKm * 1000;
          return distanceInM <= radiusInM;
        });

        return filtered;
      }), /** Convert EventItem[] to GthEventItemModel[] */
      map((events) => events.map((event) => new GthEventItemModel(event.id, event))),
    );
  }
}
