import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { Availability, TimeRanges } from '../../../../../../../../../../../shared/interfaces/user';

@Component({
  selector: 'app-availability-toggles',
  templateUrl: './availability-toggles.component.html',
  styleUrls: ['./availability-toggles.component.scss'],
})
export class AvailabilityTogglesComponent {
  @Input() availability: Availability;
  @Output() availabilityChange = new EventEmitter<Availability>();
  public TimeRanges = TimeRanges;

  onSlideToggleChange(event: MatSlideToggleChange) {
    this.availability.toggle = event.checked;
    this.availabilityChange.emit(this.availability);
  }

  onCheckboxChange(event: MatCheckboxChange, time: TimeRanges) {
    if (event.checked && !this.availability.times.includes(time)) {
      this.availability.times.push(time);
    } else {
      this.availability.times.splice(this.availability.times.indexOf(time), 1);
    }
    this.availabilityChange.emit(this.availability);
  }
}
