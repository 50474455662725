import { StripeSubscription } from '@index/interfaces';

import { GthModel } from './model';

export class GthStripeSubscriptionModel extends GthModel<StripeSubscription> {
  get stripeSubscription(): StripeSubscription {
    return this.model;
  }

  get uid() {
    return this.stripeSubscription.uid;
  }

  get id() {
    return this.stripeSubscription.id;
  }

  get item() {
    return this.stripeSubscription.item;
  }

  get label() {
    return this.stripeSubscription.label;
  }

  get priceId() {
    return this.stripeSubscription.priceId;
  }

  get cost() {
    return this.stripeSubscription.cost;
  }

  get features() {
    return this.stripeSubscription.features;
  }

  constructor(id: string, model: StripeSubscription) {
    super(id, model);
  }
}
