import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationsService } from '@sentinels/services/firebase/notifications.service';
import { catchError, map, mergeMap, of, tap } from 'rxjs';

import * as Notification from './actions';

@Injectable()
export class NotificationsEffects {
  readonly loadNotifications$ = createEffect(() => this.actions$.pipe(
    ofType(Notification.notificationLoad),
    mergeMap(({ userId }) =>
      this.notificationsService.getNotifications$(userId).pipe(
        map((notifications) =>
          Notification.notificationLoadSuccess({ notifications }),
        ),
        catchError((error) => of(Notification.notificationLoadError({ error }))),
      ),
    ),
  ));

  readonly readNotification$ = createEffect(() => this.actions$.pipe(
    ofType(Notification.notificationRead),
    mergeMap(({ userId, notificationId }) =>
      this.notificationsService.readNotification(userId, notificationId).pipe(
        map(() => Notification.notificationReadSuccess({ notificationId })),
        catchError((error) => of(Notification.notificationReadError({ error }))),
      ),
    ),
  ));

  readonly readAllNotifications$ = createEffect(() => this.actions$.pipe(
    ofType(Notification.notificationReadAll),
    mergeMap(({ userId }) =>
      this.notificationsService.markAllNotificationsAsRead(userId).pipe(
        map(() => Notification.notificationReadAllSuccess()),
        catchError((error) => of(Notification.notificationReadAllError({ error }))),
      ),
    ),
  ));

  readonly addNotification$ = createEffect(() => this.actions$.pipe(
    ofType(Notification.notificationAdd),
    mergeMap(({ userId, notification }) => {
      return this.notificationsService.addNotification$(userId, notification).pipe(
        map(() => Notification.notificationAddSuccess({ notification })),
        catchError((error) => of(Notification.notificationAddError({ error }))),
      );
    }),
  ));

  readonly addManyNotifications$ = createEffect(() => this.actions$.pipe(
    ofType(Notification.notificationAddMany),
    mergeMap(({ userIds, notification }) => {
      return this.notificationsService.addManyNotifications$(userIds, notification).pipe(
        map(() => Notification.notificationAddManySuccess({ userIds })),
        tap(() => this.snackbar.open('All notifications sent successfully', 'OK')),
        catchError((error) => {
          this.snackbar.open('Failed to send notifications', 'OK');
          return of(Notification.notificationAddManyError({ error }));
        }),
      );
    }),
  ));

  readonly removeNotification$ = createEffect(() => this.actions$.pipe(
    ofType(Notification.notificationRemove),
    mergeMap(({ userId, notificationId }) =>
      this.notificationsService.removeNotification(userId, notificationId).pipe(
        map(() => Notification.notificationRemoveSuccess({ id: notificationId })),
        catchError((error) => of(Notification.notificationRemoveError({ error }))),
      ),
    ),
  ));

  readonly clearNotifications$ = createEffect(() => this.actions$.pipe(
    ofType(Notification.notificationClear),
    mergeMap(({ userId }) => {
      return this.notificationsService.clearNotifications(userId).pipe(
        map(() => Notification.notificationClearSuccess()),
        catchError((error) => of(Notification.notificationClearError({ error }))),
      );
    }),
  ));

  private notificationsService = inject(NotificationsService);
  private snackbar = inject(MatSnackBar);

  constructor(
    private actions$: Actions<Notification.NotificationsActionsUnion>,
  ) {}

  loadNotifications() {

  }
}
