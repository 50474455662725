@if (spinner.loading$ | async) {
<mat-progress-bar mode="indeterminate" class="spinner" />
}
@if (progress.progress$ | async; as spinnerProgress) {
<mat-progress-bar mode="determinate" [value]="spinnerProgress" class="spinnerProgress" />
}
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav mode="over" position="end" #sidenavRef [opened]="!!sidenav.ctrl()"
    (openedChange)="onSidenavChange($event)">
    @if (sidenav.ctrl(); as sidenav) {
    <div class="sidenav">
      <h2 class="sidenav-header">{{ sidenav.title }}</h2>
      <button mat-icon-button (click)="sidenavRef.close()">
        <mat-icon>close</mat-icon>
      </button>
      <div class="sidenav-content">
        <ng-template [ngTemplateOutlet]="sidenav.portalContent" />
      </div>
    </div>
    }
  </mat-sidenav>

  <mat-sidenav-content class="main">
    <router-outlet />
  </mat-sidenav-content>
</mat-sidenav-container>
@switch (isOnline) {
@case (true) {
<p class="environment-name">
  {{ environmentName }} - online {{modalVersion}}
</p>
}
@case (false) {
<p class="environment-name">
  {{ environmentName }} - offline {{modalVersion}}
</p>
}
}
