import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { authReducer } from '@gth-legacy';
import { StoreModule } from '@ngrx/store';

import { AuthLayoutComponent } from './layout/auth-layout.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { LoginComponent } from './views/login/login.component';
import { MoreInfoModule } from './views/more-info/more-info.module';
import { SignUpComponent } from './views/sign-up/sign-up.component';

@NgModule({
  imports: [
    StoreModule.forFeature('authFeature', authReducer),
    LoginComponent,
    ForgotPasswordComponent,
    SignUpComponent,
  ],
  exports: [MoreInfoModule, LoginComponent, ForgotPasswordComponent, SignUpComponent,
    RouterModule],
  providers: [],
})
export class AuthModule {}
