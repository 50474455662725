import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { EventItemService } from '../../../../../../sentinels/src/lib/services/firebase/event-items.service';
import * as Profile from './actions';

@Injectable()
export class ProfileEffects {
  readonly loadAllEventsForUser$ = createEffect(() => this.actions$.pipe(
    ofType(Profile.ActionTypes.ProfileEventsLoadListByUserId),
    mergeMap((action) =>{
      return this.eventService.getEventsForUser(action.id).pipe(
        map((returnedObj) => {
          return Profile.eventsLoadListSuccess({
            events: returnedObj.events,
            joiners: returnedObj.joiners,
          });
    }),
        catchError((error) => of(Profile.eventsLoadListError({ error }))),
      );
    }),
  ));

  constructor(
    private actions$: Actions<Profile.ProfileEventsActionsUnion>,
    private eventService: EventItemService,
  ) {}
}
