<div class="date-range-container" [formGroup]="dateRangeForm">
  <div class="date-range-selection">
    @if (showCheckbox) {
      <mat-checkbox formControlName="isAvailable" (change)="onCheckboxChange($event)">
        {{ textBetweenCheckboxAndInput }}
        <span class="dash">-</span>
        {{ isAvailable.value ? 'Available' : 'Unavailable' }}
      </mat-checkbox>
    }

    @if (showAddTimeButton && (!showCheckbox || isAvailable.value)) {
      <button mat-icon-button (click)="addTimeRange()">
        <mat-icon fontIcon="add" />
      </button>
    }
  </div>

  @if (!showCheckbox || isAvailable.value) {
    <div class="time-input-container" formArrayName="timeRanges">
      @for (timeRangeControl of timeRanges.controls; track timeRangeControl; let i = $index) {
        <div class="time-range" [formGroupName]="i">
          <mat-form-field appearance="outline" class="start-time">
            <mat-label>Start Time</mat-label>
            <input matInput type="time" formControlName="startTime" required />
          </mat-form-field>
          <span class="dash">-</span>
  
          <mat-form-field appearance="outline" class="end-time">
            <mat-label>End Time</mat-label>
            <input matInput type="time" formControlName="endTime" required />
          </mat-form-field>
  
          @if (showRemoveButton) {
            <button mat-icon-button (click)="removeTimeRange(i)">
              <mat-icon fontIcon="clear" />
            </button>
          }
          <!-- Display error message for overlapping time ranges -->
          @if (timeRanges.controls[i].errors?.overlappingTimes) {
            <div class="error-message">
              Overlapping times with another range.
            </div>
          }
  
          <!-- Additional check to compare current time range with other time ranges -->
          @for (_ of timeRanges.controls; track j; let j = $index) {
            @if (i !== j && checkOverlap(i, j)) {
              <div class="error-message">
                Overlapping times with another range.
              </div>
            }
          }
        </div>
      }
    </div>
  }
</div>
