import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { CalendarComponentModule } from '../calendar/calendar.module';
import { AvailabilityDialogComponent } from './availability-dialog.component';

@NgModule({
  declarations: [AvailabilityDialogComponent],
  imports: [
    MatButtonModule,
    MatDialogModule,
    CalendarComponentModule],
  exports: [],
})
export class AvailabilityDialogModule {}
