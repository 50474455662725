import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { afterNextRender, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, isDevMode, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { ArkLoadingProgressService } from '@ark/services/progress.service';
import { environment } from '@environments/environment';
import { ANALYTICS_CONSTS, GthAnalyticsService, GthAuthService, GthLoadingService } from '@gth-legacy';
import { SrvSafeStorageService } from '@sentinels/services/safe-storage.service';
import { SrvSafeWindowService } from '@sentinels/services/safe-window.service';
import { AppWelcomeDialogComponent } from '@shared/dialogs/welcome-dialog/welcome-dialog.component';
import { PageTitleService } from '@shared/services/page-title.service';
import { AppSidenavService } from '@shared/services/sidenav.service';
import { UnsafeBrowserService } from '@shared/services/unsafe-browser.service';
import { OneSignal } from 'onesignal-ngx';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

const WELCOME_STORE = 'WELCOME_STORE';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    MatProgressBarModule,
    MatSidenavModule,
    NgTemplateOutlet,
    RouterOutlet,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('pwaDialogContent', { static: true })
  pwaDialogContent!: TemplateRef<any>;

  private subscriptions = new Subscription();
  environmentName = isDevMode() ? environment.envName : '';
  isLoggedIn = new BehaviorSubject(false);
  isOnline: boolean;
  modalVersion: boolean;
  isFirstVisit = false;

  private offlineSnackBarRef?: MatSnackBarRef<TextOnlySnackBar>;

  constructor(
    private dialog: MatDialog,
    private titleService: Title,
    private auth: GthAuthService,
    private oneSignal: OneSignal,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    readonly spinner: GthLoadingService,
    readonly progress: ArkLoadingProgressService,
    private pageTitle: PageTitleService,
    private iconRegistry: MatIconRegistry,
    private analytics: GthAnalyticsService,
    private safeWindow: SrvSafeWindowService,
    public sidenav: AppSidenavService,
    private safeStorage: SrvSafeStorageService,
    private unsafeService: UnsafeBrowserService,
    private remoteConfig: AngularFireRemoteConfig,
  ) {
    this.remoteConfig.fetchAndActivate();

    // Add SVG icon to MatIconRegistry
    this.iconRegistry.addSvgIcon(
      'gth-organize',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/gth-organize.svg'),
    );

    afterNextRender(() => {
      if (!this.getDisplayedWelcomeMessage()) {
        this.displayWelcomeMessage();
      }
    });
  }

  ngOnInit() {
    // Check visit count and set isFirstVisit accordingly
    let visitCount = parseInt(this.safeStorage.getItem('visitCount'));
    if (isNaN(visitCount)) {
      this.isFirstVisit = true;
      visitCount = 1;
    } else {
      this.isFirstVisit = false;
      visitCount++;
    }
    this.safeStorage.setItem('visitCount', visitCount.toString());

    // Load OneSignal
    this.loadOneSignal();

    // Check online status
    this.updateOnlineStatus();

    // Subscribe to title changes
    this.subscriptions.add(
      this.pageTitle.title$.pipe(
        distinctUntilChanged(),
      ).subscribe((val: string) => {
        this.titleService.setTitle(val);
      }),
    );

    // Log app open event
    this.analytics.logEvent(ANALYTICS_CONSTS.appOpen, { 'component': 'AppComponent' });

    // Check if window is blacklisted and mark as unsafe
    if (this.safeWindow.blacklisted) {
      this.unsafeService.markUnsafe();
    }

    // Remove PWA installation flag
    this.safeStorage.removeItem('gth-pwa-installed');

    // Subscribe to authentication changes
    this.subscriptions.add(
      this.auth.isLoggedIn$.pipe(
        distinctUntilChanged(),
      ).subscribe((val) => {
        this.isLoggedIn.next(val);
        if (val) {
          this.auth.getCurrentUser$().subscribe((user) => {
            if (user) {
              this.oneSignal.login(user.uid);
            }
          });
        }
      }),
    );

    // Add event listeners for online/offline events
    if (this.safeWindow.hasWindow) {
      window.addEventListener('online', this.updateOnlineStatus.bind(this));
      window.addEventListener('offline', this.updateOnlineStatus.bind(this));
    }
  }

  ngAfterViewInit() {
    // Initialize notification sound
    this.pageTitle.newNotifications.initSound();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.unsubscribe();
  }

  private updateOnlineStatus(): void {
    // Update online status based on navigator status
    this.isOnline = this.safeWindow.navigator.onLine;

    // Show offline snackbar if offline
    if (!this.isOnline) {
      this.offlineSnackBarRef = this.snackBar.open(
        'Offline: No Internet Connection',
        '',
        { duration: 0 },
      );
    } else {
      // Close offline snackbar if online
      if (this.offlineSnackBarRef) this.offlineSnackBarRef.closeWithAction();
    }
  }

  private loadOneSignal() {
    // Initialize OneSignal if window exists and not localhost
    if (this.safeWindow.hasWindow && !this.isLocalhost()) {
      this.oneSignal.init({
        appId: this.getOneSignalAppId(),
        notifyButton: {
          enable: true,
        },
      });
    }
  }

  private isLocalhost() {
    // Check if window is localhost
    return this.safeWindow.isLocalhost;
  }

  private getOneSignalAppId() {
    // Return appropriate OneSignal app id based on environment
    return environment.envName.includes('prod') ?
      'a328513d-69cf-4de9-840a-d7f6c187d4b9' :
      '5b1486fd-dc9e-4703-879f-a4bf02c9c874';
  }

  onSidenavChange(opened: any) {
    // Clear sidenav content if closed
    if (!opened) {
      this.sidenav.ctrl.set(undefined);
    }
  }

  private getDisplayedWelcomeMessage() {
    // Check if welcome message has been displayed
    if (this.safeStorage) {
      const store = this.safeStorage.getItem(WELCOME_STORE);
      if (store && store === 'beta') {
        return true;
      }
    }
    return false;
  }

  private displayWelcomeMessage() {
    // Display welcome message if not displayed before
    if (this.safeStorage) {
      this.safeStorage.setItem(WELCOME_STORE, 'beta');
      this.dialog.open(AppWelcomeDialogComponent, {
        height: '450px',
        width: '500px',
        backdropClass: 'gth-overlay-backdrop',
        panelClass: 'gth-dialog--custom-size',
      });
    }
  }
}
