import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  GthAuthService,
} from '@gth-legacy';
import { Availability } from '@index/interfaces';
import { GthUserModel } from '@sentinels/models';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-more-info-availability',
  templateUrl: './more-info-availability.component.html',
  styleUrls: ['./more-info-availability.component.scss'],
})
export class MoreInfoAvailabilityComponent implements OnInit, OnDestroy {
  public user$?: Observable<GthUserModel>;
  private userAvailabilitySubject = new BehaviorSubject<Availability[]>([]);
  private subscription = new Subscription();

  constructor(
    private auth: GthAuthService,
  ) {}
  ngOnInit() {
    this.user$ = this.auth.userModel$.pipe(
      tap((user) => this.userAvailabilitySubject.next(user?.availability)),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
