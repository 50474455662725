import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ArkLoadingProgressService {
  public progress$ = new BehaviorSubject<number>(0);
  set progress(value: number) {
    this.progress$.next(value);
  }
}
