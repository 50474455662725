@if (tasks.length > 0) {
  <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon color="warn" class="warning-icon">warning</mat-icon>
          <p>Incomplete Tasks (Click to view)</p>
        </mat-panel-title>

      </mat-expansion-panel-header>
      <div appearance="outlined" class="main-container">
        @for (task of tasks; track task) {
          <a href=""
            class="task-btn"
            mat-stroked-button
            color="warn"
            [routerLink]="task.url"
            [queryParams]="task.params"
            queryParamsHandling="merge"
            >
            {{task.text}}
          </a>
        }
      </div>
    </mat-expansion-panel>
  </mat-accordion>
}
