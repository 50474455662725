<a
  [style.border-color]="bannerBorderOklch()" 
  [style.background-color]="bannerBgOklch()"
  [class.general-card]="generalCard()"
  [routerLink]="cardLink()"
>
  @if (!generalCard() && event()) {
    <picture>
      @defer (when !!bannerImg()) {
        @if (bannerImg().imageKit) {
          <img #bannerImage [ngSrc]="bannerImg().src" [width]="108" [height]="108" alt="event image" />
        } @else {
          <img #bannerImage [src]="bannerImg().src" alt="event image" />
        }
      }
    </picture>

    <div >
      <h3>{{ event().title | titlecase }}</h3>
      <h4>
        <mat-icon>access_time_filled</mat-icon>
        {{ event().dateStart | date:'EEE MMM d, h:mm a' }}
      </h4>
    </div>
  } @else {
    <mat-icon>sports_football</mat-icon>
    <h3>View all your<br />upcoming events</h3>
  }
</a>
