import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { SportsTogglesComponent } from './sports-toggles.component';

@NgModule({
  declarations: [SportsTogglesComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatSlideToggleModule,
    MatCheckboxModule,
  ],
  exports: [SportsTogglesComponent],
})
export class SportsTogglesModule { }
