// TODO: Circular dependency
import { EventInfoEffects } from '@gth-legacy/components/event-info/state/effects';

import { EventItemListEffects } from '../../../../gth/src/app/features/discover/game/list/state/effects';
import { ProfileEffects } from '../../../../gth/src/app/features/profile/state/effects';
import { ActiveTeamsEffects } from './features/active-team/effects';
import { AuthEffects } from './features/auth/effects';
import { EventEffects } from './features/events/effects';
import { InvoicesEffects } from './features/invoices/effects';
import { JoinerEffects } from './features/joiners/effects';
import { NotificationsEffects } from './features/notifications/effects';
import { TeamsEffects } from './features/teams/effects';
import { UnregisteredUserEffects } from './features/unregistered-user/effects';
import { UserEffects } from './features/user/effects';

export const APP_EFFECTS = [
    EventItemListEffects,
    AuthEffects,
    EventInfoEffects,
    EventEffects,
    UserEffects,
    UnregisteredUserEffects,
    JoinerEffects,
    ProfileEffects,
    InvoicesEffects,
    TeamsEffects,
    ActiveTeamsEffects,
    NotificationsEffects,
];
