@if (loading) {
<div class="fx-auto fx-centered">
  <mat-spinner [diameter]="36" />
</div>
} @else {
<div class="weekly-schedule-container">
  <div class="schedule-header">
    <h2>Available hours</h2>
    <p>Set the times that people will be able to request you as a sub/participant in their events</p>
  </div>

  <div class="day-schedule fx-column fx-gap-10x">
    @for (day of userAvailability?.weeklyAvailability | keyvalue; track $index) {
    <mat-card>
      @if (!refreshDateRange) {
      <gth-legacy-date-range [showCheckbox]="true" [showRemoveButton]="true" [showAddTimeButton]="true"
        [textBetweenCheckboxAndInput]="day.key | titlecase"
        [(availabilityDay)]="userAvailability?.weeklyAvailability[day.key.toLowerCase()]"
        (dateRangeFormChange)="onDateRangeChange(day.key.toLowerCase(), $event)" />
      }
      <div class="fx-row">
        <button class="copy-btn" (click)="onCopy(userAvailability?.weeklyAvailability[day.key.toLowerCase()])"
          mat-flat-button color="accent">
          Copy To All Other Days
        </button>
      </div>
    </mat-card>
    }

  </div>

  <section class="date-specific">
    <h3>Date-specific hours</h3>
    <p>
      Override your availability specific dates from when your hours differ from your regular weekly hours.
    </p>
    <ul class="date-specific-times">
      @for (dateSpecificTime of userAvailability?.dateSpecificAvailability; track i; let i = $index) {
      <li>
        <span>{{dateSpecificTime.date | date}}</span>
        <span>
          @for (timeRange of dateSpecificTime.timeRanges; track $index) {
          {{timeRange.startTime}} - {{timeRange.endTime}}
          }
        </span>
        <button mat-icon-button type="button" (click)="onRemoveDateSpecificTime(i)">
          <mat-icon fontIcon="close" />
        </button>
      </li>
      }
    </ul>
    <button mat-stroked-button type="button" (click)="onAddDateSpecificBtnClick()">
      <mat-icon fontIcon="add" />
      <span>Add date-specific hours</span>
    </button>
  </section>

  <button mat-raised-button type="button" color="primary" [disabled]="!invalidForms.isEmpty()" class="save-btn"
    (click)="onSaveBtnClick()">Save</button>
</div>
}