import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe, JsonPipe, KeyValuePipe, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AvailabilityDay, DateSpecificAvailability, Day } from '@index/interfaces';
import { GthUserModel, SrvAvailabilityModel } from '@sentinels/models';
import { SrvUserAvailabiiltyService } from '@sentinels/services';
import { first } from 'rxjs/operators';

import { AddDateSpecificAvailabilityDialogComponent } from '../../dialogs/add-date-specific-availability-dialog/add-date-specific-availability-dialog.component';
import { GthAuthService } from '../../services';
import { DateRangeComponent } from '../date-range/date-range.component';

@Component({
  selector: 'gth-legacy-weekly-schedule',
  templateUrl: './weekly-schedule.component.html',
  styleUrls: ['./weekly-schedule.component.scss'],
  standalone: true,
  imports: [
    DateRangeComponent,
    MatButtonModule,
    MatIconModule,
    DatePipe,
    MatProgressSpinnerModule,
    MatCardModule,
    KeyValuePipe,
    TitleCasePipe,
    JsonPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeeklyScheduleComponent implements OnInit {
  protected readonly daysOfWeek: string[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  userAvailability: SrvAvailabilityModel = new SrvAvailabilityModel(undefined, {
    weeklyAvailability: {
      monday: { isAvailable: false, timeRanges: [] },
      tuesday: { isAvailable: false, timeRanges: [] },
      wednesday: { isAvailable: false, timeRanges: [] },
      thursday: { isAvailable: false, timeRanges: [] },
      friday: { isAvailable: false, timeRanges: [] },
      saturday: { isAvailable: false, timeRanges: [] },
      sunday: { isAvailable: false, timeRanges: [] },
    },
    dateSpecificAvailability: [],
  });
  editing = false;
  private user?: GthUserModel;
  loading = true;
  refreshDateRange = false;
  invalidForms = new SelectionModel<string>(true, []);

  constructor(
    private userAvailabilityService: SrvUserAvailabiiltyService,
    private authService: GthAuthService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    this.user = await this.authService.userModel$.pipe(first()).toPromise();
    if (!this.user) return;
    const userAvailability = await this.userAvailabilityService.read(this.user.uid);
    if (userAvailability) this.userAvailability = userAvailability;
    this.editing = !!userAvailability;
    this.loading = false;

    this.cdr.markForCheck();
  }

  async onSaveBtnClick() {
    if (!this.user) return;

    /** Remove null time ranges if any */
    for (const day in this.userAvailability.weeklyAvailability) {
      // Check if "day" is an own property (not inherited)
      if (this.userAvailability.weeklyAvailability.hasOwnProperty(day)) {
        // eslint-disable-next-line max-len
        const availabilityDay = this.userAvailability.weeklyAvailability[day] as AvailabilityDay;
          availabilityDay.timeRanges = availabilityDay.timeRanges
            .filter((timeRange) => timeRange.startTime && timeRange.endTime);
      }
    }
    for (const dateSpecific of this.userAvailability.dateSpecificAvailability) {
      dateSpecific.timeRanges = dateSpecific.timeRanges
        .filter((timeRange) => timeRange.startTime && timeRange.endTime);
    }

    const success = this.editing ? await this.userAvailabilityService.update$(
      this.user.uid,
      this.userAvailability,
    ) : this.userAvailabilityService.create(
      this.user.uid,
      this.userAvailability.model,
    );
    if (success) {
      this.snackbar.open(
        'Successfully saved availability',
        'Dismiss',
        { duration: 5000 },
      );
    } else {
      this.snackbar.open(
        'Something went wrong saving availability',
        'OK',
        { duration: 0 },
      );
    }
  }

  onRemoveDateSpecificTime(index: number) {
    this.userAvailability.dateSpecificAvailability.splice(index, 1);
  }

  onAddDateSpecificBtnClick() {
    const dialogRef = this.dialog.open(
      AddDateSpecificAvailabilityDialogComponent,
      {
        id: 'add-date-specific-availability-dialog',
      },
    );

    dialogRef.afterClosed().pipe(first()).forEach((dateSpecific: DateSpecificAvailability) => {
      if (dateSpecific) {
        this.userAvailability.dateSpecificAvailability.push(dateSpecific);
      }
    });
  }

  onDateRangeChange(day: string, formGroup: FormGroup) {
    if (formGroup.invalid) this.invalidForms.select(day);
    else this.invalidForms.deselect(day);
  }

  onCopy(dayToCopy: AvailabilityDay) {
    const weeklyAvail = this.userAvailability.weeklyAvailability;

    for (const day in weeklyAvail) {
      if (weeklyAvail.hasOwnProperty(day)) {
        const dayToSet = day as unknown as Day;
        this.userAvailability.setWeeklyAvailability(
          dayToSet,
          dayToCopy.isAvailable,
          structuredClone(dayToCopy.timeRanges),
        );
      }
    }

    this.userAvailability = new SrvAvailabilityModel('23', this.userAvailability.model);
  }
}
