<app-nav-bar-layout>
  <app-alert-menu [tasks]="tasks"></app-alert-menu>

  @if (user()) {
    <div class="main-section-container">
      <div class="top-home-container">
        <app-home-upcoming-events
          [name]="user().user.displayName"
          [events]="userEvents()"
        ></app-home-upcoming-events>

        <ark-location-card
          [city]="user().defaultCity"
        ></ark-location-card>
      </div>

      <app-nearby-events
        [cityName]="user().defaultCity.name"
        [eventPlayerObjects]="nearbyEventPlayerObjects()"
      ></app-nearby-events>

      <app-nearby-players
        [users]="users()"
      ></app-nearby-players>
    </div>
  }

</app-nav-bar-layout>
