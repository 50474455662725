import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { WeeklyScheduleComponent } from '@gth-legacy';

import { AvailabilityTogglesModule } from './availability-toggles/availability-toggles.module';
import { MoreInfoAvailabilityComponent } from './more-info-availability.component';

const ANGULAR_MATERIAL_MODULES = [
  MatCheckboxModule, MatSlideToggleModule,
  MatIconModule, MatButtonModule,
];

const CORE_MODULES = [CommonModule, FormsModule];

const COMPONENTS = [MoreInfoAvailabilityComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    ...CORE_MODULES, ...ANGULAR_MATERIAL_MODULES,
    AvailabilityTogglesModule, WeeklyScheduleComponent,
  ],
  exports: [...COMPONENTS],
})
export class MoreInfoAvailabilityModule { }
