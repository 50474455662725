import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { StripeModule } from 'stripe-angular';

import { PaymentsComponent } from './payments.component';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatGridListModule,
        MatCardModule,
        MatInputModule,
        MatIconModule,
        MatMenuModule,
        StripeModule,
        MatRadioModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [PaymentsComponent],
    declarations: [PaymentsComponent],
})
export class PaymentsModule { }
