import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SportsSkillType } from '@index/enums';
import { GthSportAvailability } from '@index/interfaces';

@Component({
  selector: 'app-sports-toggles',
  templateUrl: './sports-toggles.component.html',
  styleUrls: ['./sports-toggles.component.scss'],
})
export class SportsTogglesComponent {
  @Input()
  sportsAvailability: GthSportAvailability;

  @Input()
  skillVisible = false;

  @Output()
  availabilityChange = new EventEmitter<GthSportAvailability>();

  public get SportsSkillType() {
    return SportsSkillType;
  }

  onSlideToggleChange(event: MatSlideToggleChange) {
    this.sportsAvailability.toggle = event.checked;
    this.availabilityChange.emit(this.sportsAvailability);
  }

  onCheckboxChange(event: MatCheckboxChange, skill: SportsSkillType) {
    if (event.checked && this.sportsAvailability.skill !== skill) {
      this.sportsAvailability.skill = skill;
    } else {
      this.sportsAvailability.skill = null;
    }
    this.availabilityChange.emit(this.sportsAvailability);
  }
}
