import { inject, Injectable } from '@angular/core';
import { NotificationType } from '@index/enums';
import { Notification } from '@index/interfaces';
import { NotificationModel } from '@index/models/notifications';
import { GthBadgeModel, GthConversationModel, GthEventItemModel, GthNotificationModel, GthTeamModel, GthUserModel } from '@sentinels/models';
import { BadgesService } from '@sentinels/services/firebase/badges.service';
import { EventItemService } from '@sentinels/services/firebase/event-items.service';
import { TeamRosterService } from '@sentinels/services/firebase/team-roster.service';
import { TeamsService } from '@sentinels/services/firebase/teams.service';
import { UserService } from '@sentinels/services/firebase/user.service';
import { Timestamp } from 'firebase/firestore';
import { catchError, EMPTY, first, forkJoin, from, map, Observable, of, startWith, switchMap, take, timeout } from 'rxjs';

import { MessageService } from '../../../../../gth/src/app/features/messages/services/message.service';
import { FirestoreService } from '../core/firebase.service';

export type NotificationMetadata = {
    joiner?: Observable<GthUserModel | undefined>;
    event?: Observable<GthEventItemModel | undefined>;
    user?: Observable<GthUserModel | undefined>;
    conversation?: Observable<GthConversationModel | undefined>;
    team?: Observable<GthTeamModel | undefined>;
    badge?: Observable<GthBadgeModel | null>;
};

@Injectable({
  providedIn: 'root',
})
export class NotificationsService extends FirestoreService<NotificationModel> {
  protected basePath: string = 'users';

  private messageService = inject(MessageService);
  private badgesService = inject(BadgesService);
  private usersService = inject(UserService);
  private eventsService = inject(EventItemService);
  private teamsService = inject(TeamsService);
  private teamRosterService = inject(TeamRosterService);

  private metaDataCache: { [notificationId: string]: NotificationMetadata } = {};

  getNotifications$(userId: string): Observable<GthNotificationModel[]> {
    return this.doc(userId)
      .collection('notifications', (ref) => ref.orderBy('createdAt', 'desc'))
      .valueChanges({ idField: 'id' })
      .pipe(
        switchMap((notifications) => {
          if (!notifications || notifications.length === 0) {
            return of([]);
          }
          const notifs$ = notifications.map((notification) => {
            if (notification.createdAt && (notification.createdAt as Timestamp).toDate) {
              notification.createdAt = (notification.createdAt as Timestamp).toDate();
            }
            if (!this.metaDataCache[notification.id]) {
              return this.getMetaData(
                notification as Notification, userId,
              );
            }
            notification.metadata = this.metaDataCache[notification.id];
            const notificationModel = new GthNotificationModel(
              notification.id,
              notification as Notification,
            );
            return of(notificationModel);
          });
          return forkJoin(notifs$);
        }),
        map((notifications) => {
          const filteredNotifications: GthNotificationModel[] = [];
          for (const notification of notifications) {
            switch (notification.type) {
              case NotificationType.RATE_PLAYER:
                // Filter out duplicate Event Rating notifications
                const existingNotification = filteredNotifications
                  .find((n) => n.eventItem === notification.eventItem);
                if (!existingNotification) {
                  filteredNotifications.push(notification);
                }
                break;
              default:
                filteredNotifications.push(notification);
                break;
            }
          }
          return filteredNotifications;
        }),
        startWith([]),
        catchError((error) => {
          console.log(error);
          return EMPTY;
        }),
      );
  }

  readNotification(userId: string, notificationId: string) {
    return from(this.doc(userId).collection('notifications')
    .doc(notificationId).update({ read: true }));
  }

  addNotification$(userId: string, notification: NotificationModel) {
    const id = this.firestore.createId();

    return from(this.doc(userId).collection('notifications').doc(id).set({
      type: notification.type,
      metadata: notification.metadata,
      read: notification.read,
      createdAt: new Date(),
    }));
  }

  addManyNotifications$(userIds: string[], notification: NotificationModel) {
    const observables = userIds.map((userId) => this.addNotification$(userId, notification));
    return forkJoin(observables);
  }

  removeNotification(userId: string, notificationId: string) {
    return from(this.doc(userId).collection('notifications').doc(notificationId).delete());
  }

  clearNotifications(userId: string) {
    return from(this.doc(userId).collection('notifications').get().forEach((snapshot) => {
      snapshot.docs.forEach((doc) => doc.ref.delete());
    }));
  }

  markAllNotificationsAsRead(userId: string) {
    return from(this.doc(userId).collection('notifications').get().forEach((snapshot) => {
      snapshot.docs.forEach((doc) => doc.ref.update({ read: true }));
    }));
  }

  markNotificationAsUnread(userId: string, notificationId: string) {
    return this.doc(userId).collection('notifications')
      .doc(notificationId)
      .update({ read: false });
  }

  getMetaData(notification: Notification, userId: string): Observable<GthNotificationModel> {
    const metadata: NotificationMetadata = {};

    if (notification.metadata?.joiner) {
      // eslint-disable-next-line max-len
      metadata.joiner = this.usersService.getUserById$(notification.metadata.joiner as string)
        .pipe(
          take(1));
    }

    if (notification.metadata?.event) {
      metadata.event = from(this.eventsService.list({
        eventItemId: notification.metadata.event as string ?? 'none',
      })).pipe(
        first(),
        map((eventlist) => {
          if (!eventlist) {
            return undefined;
          }
          return eventlist[0];
        }),
      );
    }

    if (notification.metadata?.user) {
      metadata.user = this.usersService.getUserById$(notification.metadata.user as string)
        .pipe(
          take(1));
    }

    if (notification.metadata?.conversation) {
      metadata.conversation = this.messageService
        .getConversation(notification.metadata.conversation as string, userId)
        .pipe(
          take(1));
    }

    if (notification.metadata?.team) {
      // TODO: get team from store
      metadata.team = this.teamsService.getTeamByTeamId$(notification.metadata.team as string)
        .pipe(
          map((team) => team ? new GthTeamModel(team.id, team) : undefined),
          switchMap(async (team) => {
            if (!team) return undefined;
            team.roster = await this.teamRosterService.getUsersByTeamId(team.id);
            return team;
          }),
          take(1),
        );
    }

    if (notification.metadata?.badge) {
      // eslint-disable-next-line max-len
      metadata.badge = this.badgesService.getBadgeById$(notification.metadata.badge as string)
        .pipe(
          take(1));
    }

    return forkJoin(metadata).pipe(
      timeout(5000),
      map((partialMetadata) => {
        notification.metadata = {
          ...notification.metadata,
          ...partialMetadata,
        };
        this.metaDataCache[notification.id] = notification.metadata;
        const notificationModel = new GthNotificationModel(notification.id, notification);
        return notificationModel;
      }),
    );
  }
}
