import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { AccountSubscriptionType } from '@sentinels/enums';
import { GthStripeSubscriptionModel } from '@sentinels/models';
import { Observable } from 'rxjs';

import { GthStripeSubscriptionsService } from '../../services/cloud/stripe-subscriptions.service';

@Component({
  selector: 'gth-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule, MatSelectModule,
    MatButtonModule, MatDividerModule,
    MatIconModule, MatProgressSpinnerModule,
  ],
})
export class SubscriptionsComponent implements OnInit {
  @Input()
  activeSubscription = 'Free';

  @Input()
  subscriptionType = AccountSubscriptionType.TEAMS;

  @Input()
  platform: 'gth' | 'meh' = 'gth';

  @Output()
  subscriptionChange = new EventEmitter<GthStripeSubscriptionModel>();

  subscriptions$?: Observable<GthStripeSubscriptionModel[]>;

  subscriptionIncrement = 'month';

  constructor(
    private stripeSubscriptionsService: GthStripeSubscriptionsService,
  ) {}
  ngOnInit() {
    switch (this.subscriptionType) {
      case AccountSubscriptionType.USER:
        this.subscriptions$ = this.platform === 'meh' ?
          this.stripeSubscriptionsService.listMeh$() :
          this.stripeSubscriptionsService.listGth$();
        this.subscriptionIncrement = 'year';
        break;
      default:
        this.subscriptions$ = this.stripeSubscriptionsService.list$();
        this.subscriptionIncrement = 'month';
        break;
    }
  }

  onSubscriptionChange(subscription: GthStripeSubscriptionModel) {
    this.subscriptionChange.emit(subscription);
  }

  onSubscriptionBtnClick(subscription: GthStripeSubscriptionModel) {
    this.subscriptionChange.emit(subscription);
  }

  getSubscription(subscriptions: GthStripeSubscriptionModel[], subscription: string) {
    return subscriptions.find((s) => s.label === subscription);
  }

  protected readonly AccountSubscriptionType = AccountSubscriptionType;
}
