import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
     private mockAccount = new BehaviorSubject(null);

    getAccount$(): Observable<any> {
        // TODO: Add logic here
        return this.mockAccount;
    }

    deleteAccount() {
        this.mockAccount.next(null);
    }

    createAccount(data: any) {
        this.mockAccount.next(data);
    }

    hasAccount() {
        return !!this.mockAccount.getValue();
    }
}
