import { JsonPipe } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'gth-stripe-pricing-table',
  templateUrl: './stripe-pricing-table.component.html',
  styleUrl: './stripe-pricing-table.component.scss',
  standalone: true,
  imports: [
    MatProgressSpinner,
    JsonPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class StripePricingTableComponent implements OnInit {
  @Input()
  id?: string;

  @Input()
  platform: 'gth' | 'meh' = 'gth';

  @Input()
  publishableKey: string;

  @Input()
  customerEmail?: string;

  @Input()
  envName: 'prod' | 'dev' = 'prod';

  loading = true;

  ngOnInit() {
    switch (this.platform) {
      case 'gth':
        this.id = this.envName === 'prod' ?
          'prctbl_1OvjNREGIuJxcjmD4phbr3bd' : 'prctbl_1OvVsWEGIuJxcjmDvYKKjba4';
        break;
      case 'meh':
        this.id = this.envName === 'prod' ?
          'prctbl_1OvYnpEGIuJxcjmDz0wpX1gz' : 'prctbl_1OvXyYEGIuJxcjmDliYbEApC';
        break;
      default:
        // eslint-disable-next-line max-len
        throw Error('This component must be called with a platform to render a stripe pricing table');
        break;
    }
    if (this.id && this.publishableKey) this.loading = false;
    else {
      console.debug(`${this.id ? 'Publishable key' : 'ID'} missing for stripe pricing table`);
    }
  }
}
