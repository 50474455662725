<app-nav-bar-layout [navBarOptions]="navBarOptions">
  <div class="wrapper fx-column" *ngIf="{user: user$ | async} as auth">
    <div class="content fx-auto fx-column">
      <div class="search-bar-container">
        <app-search-bar pageTitle="Upcoming Events" context="events" [defaultCity]="defaultCity$ | async"
          (createButtonClick)="onCreateGameButtonClick()" (filter)="onSearchPerformed($event)">
          <a  color="primary" [routerLink]="['/create']" mat-raised-button>Create an Event</a></app-search-bar>
      </div>
      <div class="onboarding-container" *ngIf="displayOnboardingStep && !displayMockResults">
        <gth-onboarding-step [step]="OnboardingStep.GamesSearch"
          (dismiss)="displayOnboardingStep = false"></gth-onboarding-step>
      </div>
      <div class="onboarding-container" *ngIf="displayMockResults">
        <gth-onboarding-step [step]="OnboardingStep.MockGamesSearch"></gth-onboarding-step>
      </div>
      <mat-card appearance="outlined" class="content-card fx-column fx-gap-10x">
        <ng-container *ngIf="events$ | async as events; else noResultsTmpl">
          <ng-container *ngIf="!noResults; else noResultsTmpl">
            <ng-container [ngSwitch]="viewType">
              <div class="table-container fx-column fx-auto" *ngSwitchCase="0">
                <app-games-table class="fx-auto" [user]="auth.user" [events]="events"
                  (eventClick)="onViewGameButtonClick(auth.user, $event)"></app-games-table>
              </div>
              <div class="table-container fx-column fx-auto" *ngSwitchCase="1">
                <app-game-list class="fx-auto" [user]="auth.user" [events]="events"
                  (eventClick)="onViewGameButtonClick(auth.user, $event)"></app-game-list>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </mat-card>
    </div>
  </div>
</app-nav-bar-layout>

<ng-template #loadingTmpl>
  <div class="fx-centered fx-fill">
    <mat-spinner diameter="42"></mat-spinner>
  </div>
</ng-template>

<ng-template #noResultsTmpl>
  <div class="list--no-results fx-centered fx-fill" *ngIf="(isLoading |async) === false; else loadingTmpl">
    <img src="/assets/biking.webp" />
    <p>Influence New Events Near You</p>
    <button mat-raised-button color="primary" (click)="onCreateGameButtonClick()">
      Create Event
    </button>
  </div>
</ng-template>