import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArkSearchBarFilter, ArkSearchBarFilterContract } from '@ark';
import {
  APP_STATE,
  CurrentState,
  GthAuthService,
  GthGoogleMapsService,
  GthOnboardingService,
  GthOnboardingStep,
} from '@gth-legacy';
import { EventsService } from '@gth-legacy/services/events.service';
import { EventItemTypes } from '@index/enums/event-item-type';
import { Store } from '@ngrx/store';
import { GthEventItemModel, GthUserModel } from '@sentinels/models';
import { UserService } from '@sentinels/services/firebase/user.service';
import { SrvSafeStorageService } from '@sentinels/services/safe-storage.service';
import { NavBarOptions } from '@shared/layouts/nav-bar-layout/views/nav-bar.component';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { first, map, switchMap, timeout } from 'rxjs/operators';

import { DefaultCity } from '../../../../../../../../../shared/interfaces/user';
import { APP_ROUTES } from '../../../../shared/helpers';
import { ChangeStatus, LoadEventItems } from './state/actions';

enum GameListViewType {
  Table = 0,
  List = 1,
}

@Component({
  selector: 'app-games',
  templateUrl: './game-list.component.html',
  styleUrls: ['./game-list.component.scss'],
})
export class GameListComponent implements OnInit, OnDestroy {
  get isLoggedIn$() {
    return this.auth.isLoggedIn$;
  }

  get OnboardingStep() {
    return GthOnboardingStep;
  }

  navBarOptions: NavBarOptions = {
    pageName: 'Games',
  };

  lat = -34.5;
  lng = 34.5;
  routes = APP_ROUTES;
  user$!: Observable<GthUserModel>;
  defaultCity$?: Observable<{ city: DefaultCity | undefined }>;
  events$?: Observable<GthEventItemModel[] | undefined>;
  noResults = true;
  displayOnboardingStep = false;
  displayMockResults = false;
  viewType = GameListViewType.List;

  readonly types = EventItemTypes;
  private subscription = new Subscription();
  private filterSettingsSubject = new BehaviorSubject
    <ArkSearchBarFilter | undefined>(undefined);

  get isLoading() {
    return this.store.select(
      (state)=>state.eventItemListFeature.state).pipe((map((state)=>{
        return state === CurrentState.Loading;
      })));
  }

  constructor(
    readonly auth: GthAuthService,
    private onboarding: GthOnboardingService,
    private mapsService: GthGoogleMapsService,
    private safeStorage: SrvSafeStorageService,
    private eventsService: EventsService,
    private usersService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<APP_STATE>,
  ) { }

  async ngOnInit() {
    this.store.dispatch(ChangeStatus({ status: CurrentState.Loading }));
    const viewTypeStore = this.safeStorage.getItem('VIEW_TYPE');
    if (viewTypeStore) {
      const viewType = parseInt(viewTypeStore);
      if (!isNaN(viewType)) {
        this.viewType = viewType;
      }
    }
    this.user$ = this.auth.userModel$
      .pipe(
        first(),
      );
    const user = await this.auth.getUserModelAsync();
    if (user) {
      // eslint-disable-next-line max-len
      this.displayOnboardingStep = !(await this.onboarding.hasCompletedStep(GthOnboardingStep.GamesSearch));
    }
    const filter$ = this.filterSettingsSubject.asObservable();

    this.defaultCity$ = this.user$.pipe(
      map((user: GthUserModel) => {
        if (!user) {
          return { city: undefined };
        }
        return { city: user.defaultCity };
      }),
    );

    this.events$ = filter$.pipe(
      map((v) => {
        this.store.dispatch(ChangeStatus({ status: CurrentState.Loading }));
        return v;
      }),
      switchMap((filter) => this.getEvents$(filter)),
      map((v) => {
        this.store.dispatch(ChangeStatus({ status: CurrentState.Success }));
        return v;
      }),
    );

    this.subscription.add(
      this.events$.subscribe((data) => {
        this.noResults = !data || data.length === 0;
      }),
    );

    this.subscription.add(
      combineLatest(this.user$, this.mapsService.itemClicked$).subscribe(([user, evt]) => {
        this.onViewGameButtonClick(user, evt as any).then();
      }),
    );

    this.subscription.add(
      this.route.url.subscribe((segments) => {
        if (!segments || segments.length === 0) {
          return;
        }
        const lastSegment = segments[segments.length - 1];
        if (lastSegment.path === 'create') {
          this.onCreateGameButtonClick();
        }
      }),
    );

    this.subscription.add(
      this.route.data.subscribe((data) => {
        if (data['gameData'] && data['gameData'].game) {
          const gameData = data['gameData'];
          this.onViewGameButtonClick(gameData.user, gameData.game);
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onCreateGameButtonClick() {
    this.router.navigate(['/create']);
  }

  async onViewGameButtonClick(user: GthUserModel, game: GthEventItemModel) {
    this.router.navigate(['/discover/games/' + game.id]);
  }

  onFilterEmitter(result: ArkSearchBarFilter) {
    this.filterSettingsSubject.next(result);
  }

  onSearchPerformed(evt: ArkSearchBarFilterContract) {
    this.store.dispatch(ChangeStatus({ status: CurrentState.Loading }));

    if (this.displayMockResults) {
      // Reset query params to avoid user ending up with mock resutls again
      this.router.navigate([]);
    }
    this.filterSettingsSubject.next(evt.queryParams);
  }

  private getEvents$(
    filter: ArkSearchBarFilter | undefined,
  ): Observable<GthEventItemModel[] | undefined> {
    this.displayMockResults = false;
    if (!filter) {
      return of(undefined);
    }
    const city = filter.city;
    let events$ = of([]);
    if (filter.online) {
      events$ = this.eventsService.getOnlineEvents$();
    } else if (!city || !city.lat || !city.lng) {
      return of(undefined);
    } else {
      this.lat = city.lat;
      this.lng = city.lng;
      this.store.dispatch(LoadEventItems({ lat: this.lat, lng: this.lng }));
      events$ = this.store.select((state)=> state.eventItemListFeature.events);
    }

    return events$
      .pipe(
        timeout(30000),
        /** Filter out events to show only upcoming */
        map(this.eventsService.filterUpcomingEvents),
        map((events) => this.getFilteredResults(events, filter)),
        switchMap((events) => {
          const creatorIds: string[] = [];
          events.forEach((g) => {
            if (g.creatorId && !creatorIds.includes(g.creatorId)) {
              creatorIds.push(g.creatorId);
            }
          });

          const users$ = this.getParticipants$(creatorIds);
          return users$.pipe(
            map((users) => {
              if (users.length === 0) {
                return events;
              }
              events.forEach((g) => {
                if (g.creatorId) {
                  const user = users.find((u) => !!(u && u.uid === g.creatorId));
                  if (user) {
                    g.setCreator(user);
                  }
                }
              });
              return events;
            }),
          );
        }),
        switchMap((events) => this.eventsService.getEventJoiners$(events)),
        map((events) => events.sort((a, b) => a.dateStart.getTime() - b.dateStart.getTime())),
      );
  }

  private getFilteredResults(results: GthEventItemModel[], filter: ArkSearchBarFilter) {
    return results
      .filter((e) => !e.cancelled)
      .filter((e) => this.hasDays(e, []))
      .filter((e) => {
        if (filter.gameType && filter.gameType.length > 0) {
          return filter.gameType.includes(e.gameType);
        }
        return true;
      });
  }

  private hasDays(game: GthEventItemModel, days: string[]) {
    if (!days || days.length < 1) return true;

    const options = { weekday: 'long' };
    const date = new Date(game.dateStart);
    const day = new Intl.DateTimeFormat('en-US', options as Intl.DateTimeFormatOptions).format(
      date,
    );

    return !!days!.includes(day);
  }

  private getParticipants$(userIds: string[]) {
    if (!userIds || userIds.length === 0) {
      return of([]);
    }
    const requests$: Observable<GthUserModel | undefined>[] = [];
    userIds.forEach((c) => {
      const request = this.usersService.getUserById$(c);
      requests$.push(request);
    });
    return combineLatest(requests$).pipe(
      map((users) => {
        // Filter out deleted users
        return users.filter((x) => {
          return x !== undefined;
        });
      }),
    );
  }
}
