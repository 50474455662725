import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { EventJoinerStatus } from '@index/interfaces';
import { GthEventItemModel, GthUserModel } from '@sentinels/models';

enum SortType {
  NameAscending = 1,
  NameDescending = 2,
  DateAscending = 3,
  DateDescending = 4,
}

@Component({
  selector: 'app-game-list',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
  ],
  templateUrl: './game-list.component.html',
  styleUrls: ['./game-list.component.scss'],
})
export class AppGameListComponent implements OnChanges {
  @Input()
  user?: GthUserModel;

  @Input()
  events?: GthEventItemModel[];

  @Output()
  eventClick = new EventEmitter<GthEventItemModel>();

  sortType = SortType.DateDescending;
  sortedEvents: GthEventItemModel[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['events']) {
      this.sortResults();
    }
  }

  getEventJoinerStatus(
    user: GthUserModel,
    game: GthEventItemModel,
  ): EventJoinerStatus | undefined {
    // Todo(rkara): fix
    if (!user) {
      return undefined;
    }

    if (game.eventJoinerStatus) {
      return game.eventJoinerStatus;
    }

    const participants = game.participants;
    const participant = participants.find((p) => p.player === user.uid);
    if (!participant) {
      return undefined;
    }

    return game.eventJoinerStatus;
  }

  getEventJoinerStatusText(status: EventJoinerStatus | undefined) {
    switch (status) {
      case EventJoinerStatus.Approved:
        return 'You have been approved to participate in this event.';
      case EventJoinerStatus.Waitlisted:
        return 'You are currently on this event\'s waitlist.';
      case EventJoinerStatus.PendingCreator:
        return 'Your approval is pending the creator\'s approval.';
      case EventJoinerStatus.PendingApprovers:
        return 'Your approval is pending the approvers\' approval.';
      case EventJoinerStatus.Dropped:
        return 'You have opted not to participate in this event.';
      case EventJoinerStatus.Denied:
        return 'Your request to participate has been denied.';
      default:
        return '';
    }
  }

  onSortChange(sortType: SortType) {
    this.sortType = sortType;
    this.sortResults();
  }

  onViewGameButtonClick(event: GthEventItemModel) {
    this.eventClick.emit(event);
  }

  private sortResults() {
    if (!this.events) {
      this.sortedEvents = [];
      return;
    }

    this.sortedEvents = this.events.sort((a, b) => {
      switch (this.sortType) {
        case SortType.DateAscending:
          return b.dateStart.getTime() - a.dateStart.getTime();
        case SortType.DateDescending:
          return a.dateStart.getTime() - b.dateStart.getTime();
        case SortType.NameAscending:
          return a.title.localeCompare(b.title);
        case SortType.NameDescending:
          return b.title.localeCompare(a.title);
      }
    });
  }
}
