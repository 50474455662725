import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EventJoinerStatus } from '@index/interfaces/event-item';
import { GthEventItemModel, GthUserModel } from '@sentinels/models';

const DISPLAY_COLUMNS = [
  'title',
  'gameType',
  'dateStart',
  'eventType',
  'Status',
  'Actions',
];

@Component({
  selector: 'app-games-table',
  templateUrl: './games-table.component.html',
  styleUrls: ['./games-table.component.scss'],
})
export class AppGamesTableComponent implements OnChanges, AfterViewInit {
  @ViewChild(MatPaginator)
  private paginator?: MatPaginator;

  @ViewChild(MatSort)
  private sort!: MatSort;

  @Input()
  user?: GthUserModel;

  @Input()
  events?: GthEventItemModel[];

  @Output()
  eventClick = new EventEmitter<GthEventItemModel>();

  dataSource = new MatTableDataSource<GthEventItemModel>([]);
  displayedColumns = DISPLAY_COLUMNS;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['events']) {
      this.dataSource.data = this.events ?? [];
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
  }


  getEventJoinerStatus(
    user: GthUserModel | null | undefined,
    game: GthEventItemModel | null,
  ): EventJoinerStatus | undefined {
    if (!user || !game) {
      return undefined;
    }

    if (game.eventJoinerStatus) {
      return game.eventJoinerStatus;
    }

    const participants = game.participants;
    const participant = participants.find((p) => p.player === user.uid);
    if (!participant) {
      return undefined;
    }

    return game.eventJoinerStatus;
  }

  getEventJoinerStatusText(status: EventJoinerStatus | undefined | null) {
    switch (status) {
      case EventJoinerStatus.Approved:
        return 'You have been approved to participate in this event.';
      case EventJoinerStatus.Waitlisted:
        return 'You are currently on this event\'s waitlist.';
      case EventJoinerStatus.PendingCreator:
        return 'Your approval is pending the creator\'s approval.';
      case EventJoinerStatus.PendingApprovers:
        return 'Your approval is pending the approvers\' approval.';
      case EventJoinerStatus.Dropped:
        return 'You have opted not to participate in this event.';
      case EventJoinerStatus.Denied:
        return 'Your request to participate has been denied.';
      default:
        return '';
    }
  }

  async onViewGameButtonClick(event: GthEventItemModel) {
    this.eventClick.emit(event);
  }
}
