import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';

import { StripeService } from '../../services/stripe.service';

@Component({
  selector: 'gth-unlink-stripe-dialog',
  templateUrl: './unlink-stripe-dialog.component.html',
  styleUrls: ['./unlink-stripe-dialog.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
})
export class UnlinkStripeDialogComponent {
  loading = false;
  constructor(
    private dialogRef: MatDialogRef<UnlinkStripeDialogComponent>,
    private stripe: StripeService,
    private snackbar: MatSnackBar,
  ) {}
  async unLinkBtnClick() {
    try {
      this.loading = true;

      await this.stripe.unlinkStripeAccount().then((deleted) => {
        if (deleted) {
          this.snackbar.open('Your Stripe account was unlinked successful.', undefined, {
            duration: 3000,
          });
        } else {
          this.snackbar.open(
            'Failed to unlink your Stripe account, please try again.',
            undefined,
            { duration: 5000 },
          );
        }

        this.dialogRef.close(deleted);
      });
    } catch (error) {
      console.error('Something went wrong unlinking Stripe account', error);
    } finally {
      this.loading = false;
    }
  }
}
